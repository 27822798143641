import * as yup from 'yup';
import { MAX_DOLLARS_PER_KWH_VALUE } from '../../constants';

export const tieredFormSchema = yup.object().shape({
  seasons: yup.array().of(
    yup.object().shape({
      chargePeriod: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .typeError('This field is required')
        .required(),
      tiers: yup.array().of(
        yup.object().shape({
          rate: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .typeError('This field is required')
            .max(MAX_DOLLARS_PER_KWH_VALUE, `Must be less than $${MAX_DOLLARS_PER_KWH_VALUE}`)
            .min(0),
          upperLimitKWh: yup
            .number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .nullable(), // NOTE: array comparison validation occurs in the submission handler
        })
      ),
    })
  ),
});
