import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export type SchneiderChargeSetupState = {
  siteDeviceId: number | null;
  chargerId: string | null;
  bearerToken: string | null;
};

export type SchneiderChargeSetupReducers = {
  addSiteDeviceId: (state: SchneiderChargeSetupState, action: PayloadAction<number>) => void;
  addChargerId: (state: SchneiderChargeSetupState, action: PayloadAction<string>) => void;
  addBearerToken: (state: SchneiderChargeSetupState, action: PayloadAction<string>) => void;
  resetSchneiderChargeSetup: (state: SchneiderChargeSetupState, action: PayloadAction<void>) => void;
};

const initialState: SchneiderChargeSetupState = {
  siteDeviceId: null,
  chargerId: null,
  bearerToken: null,
};

export const schneiderChargeSetupSlice = createSlice<SchneiderChargeSetupState, SchneiderChargeSetupReducers>({
  name: 'schneiderChargeSetup',
  initialState,
  reducers: {
    addSiteDeviceId: (state, { payload }) => {
      return { ...state, siteDeviceId: payload };
    },
    addChargerId: (state, { payload }) => {
      return { ...state, chargerId: payload };
    },
    addBearerToken: (state, { payload }) => {
      return { ...state, bearerToken: payload };
    },
    resetSchneiderChargeSetup: () => {
      return { ...initialState };
    },
  },
});

export const { resetSchneiderChargeSetup, addSiteDeviceId, addChargerId, addBearerToken } =
  schneiderChargeSetupSlice.actions;

export const selectSiteDeviceId = (state: RootState) => {
  return state.schneiderChargeSetup.siteDeviceId;
};

export const selectChargerId = (state: RootState) => {
  return state.schneiderChargeSetup.chargerId;
};

export const selectBearerToken = (state: RootState) => {
  return state.schneiderChargeSetup.bearerToken;
};

export default schneiderChargeSetupSlice.reducer;
