import { DiscountType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import { Discount, DiscountFormItem, DiscountSelectOption } from './types';
import discountRetailerImg from '../../../../../../assets/images/discount_retailer.svg';
import discountUsageImg from '../../../../../../assets/images/discount_usage.svg';

export const DISCOUNT_OPTIONS: DiscountSelectOption[] = [
  {
    label: 'Total Bill Discount',
    value: Discount.TOTAL_BILL,
  },
  {
    label: 'Supply Charge Discount',
    value: Discount.SUPPLY_CHARGE_DISCOUNT,
  },
  {
    label: 'Usage Charge Discount',
    value: Discount.USAGE_CHARGE_DISCOUNT,
  },
];

export const EMPTY_DISCOUNT_TEMPLATE: DiscountFormItem = {
  value: null,
  type: null,
};

export const DISCOUNT_TYPE_TO_IMAGE: Record<Discount, string> = {
  [Discount.TOTAL_BILL]: discountRetailerImg,
  [Discount.SUPPLY_CHARGE_DISCOUNT]: discountUsageImg,
  [Discount.USAGE_CHARGE_DISCOUNT]: discountUsageImg,
};

export const DISCOUNT_TYPE_TO_UI_DISCOUNT_OPTION: Record<DiscountType, Discount> = {
  USAGE: Discount.USAGE_CHARGE_DISCOUNT,
  TOTAL_BILL: Discount.TOTAL_BILL,
  SUPPLY: Discount.SUPPLY_CHARGE_DISCOUNT,
};

export const UI_DISCOUNT_OPTION_TO_DISCOUNT_TYPE: Record<Discount, DiscountType> = {
  [Discount.USAGE_CHARGE_DISCOUNT]: 'USAGE',
  [Discount.TOTAL_BILL]: 'TOTAL_BILL',
  [Discount.SUPPLY_CHARGE_DISCOUNT]: 'SUPPLY',
};
