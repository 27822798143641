import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import packageJSON from '../../../package.json';
import { ENDPOINT, IS_RUNNING_CYPRESS_TESTS } from '../../common/constants';

/**
 * Configures the Sentry setup for this repo.
 */

/* istanbul ignore next -- @preserve */
export function setupSentry() {
  Sentry.init({
    release: `cortex(${packageJSON.version})@` + import.meta.env.VITE_SENTRY_RELEASE,
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    beforeSend(event) {
      if (IS_RUNNING_CYPRESS_TESTS) return null;
      // To pass the session URL with a timestamp to the moment that an error is caught by Sentry,
      // we include the LogRocket URL in Sentry's beforeSend() action
      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        if (!event.extra) event.extra = {};
        event.extra['LogRocket'] = logRocketSession;
        event.extra['AppVersion'] = packageJSON.version;
        return event;
      } else {
        return event;
      }
    },
    // This sets the sample rate to be 5%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.05,
    // If the entire session is not sampled, use the below sample
    // rate to sample sessions when an error occurs
    replaysOnErrorSampleRate: 0.05,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: [ENDPOINT, 'app.clipsalcortex.com', 'app-staging.clipsalcortex.com'],
        beforeNavigate: (context) => {
          return {
            ...context,
            name: window.location.pathname.replace(/\d+/g, ':id').replace(/[a-f0-9]{32}/g, '<hash>'),
          };
        },
      }),
      new Sentry.Replay(),
    ],
    environment: import.meta.env['MODE'],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.5,

    // This helps refine sentry error reporting by ignoring known errors that don't need fixing
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',

      // These can be safely ignored as they are non error exceptions
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
  });
}
