import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SendIntent } from 'send-intent';
import { Capacitor } from '@capacitor/core';
import { selectUser } from '../../features/user/userSlice';

// Configures functionality which allows users to share bills across from other apps, instead of uploading them.
export function useBillShare() {
  const navigate = useNavigate();
  const [billDetails, setBillDetails] = useState({ url: '', title: '' });
  const user = useSelector(selectUser);

  useEffect(() => {
    // only single site owners can access the bill upload feature for now
    /* istanbul ignore next -- @preserve */
    if (Capacitor.isNativePlatform() && billDetails.url && user.isLoggedIn && user.siteIDs.length === 1) {
      navigate(`/site/${user.siteIDs[0]}/bills?bill-url=${billDetails.url}&bill-title=${billDetails.title}`);
      setBillDetails({ url: '', title: '' });
    }
  }, [user.siteIDs.length, billDetails, user.isLoggedIn, user.siteIDs, navigate]);

  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    if (Capacitor.isNativePlatform()) {
      const billUploadActionReceived = async () => {
        try {
          const { url, title } = await SendIntent.checkSendIntentReceived();
          if (url && title) setBillDetails({ url, title });
        } catch (error) {
          console.error(error);
        }
      };

      // Only fired when app is started initially
      billUploadActionReceived();

      // Only fired when app is already running
      window.addEventListener('sendIntentReceived', billUploadActionReceived);
    }
  }, []);
}
