import * as yup from 'yup';
import { validateAtLeastOneRateProvided, validateTimesOfUse } from './utils';
import { TestFunction } from 'yup/lib/util/createValidation';
import { MAX_DOLLARS_PER_KWH_VALUE } from '../../constants';

const touTimeSchema = yup.object().shape({
  minutes: yup.number().required(),
  hours: yup.number().required(),
  amOrPm: yup.string().oneOf(['AM', 'PM']).required(),
});

const timeOfUseSchema = yup.object().shape({
  fromTime: touTimeSchema,
  toTime: touTimeSchema,
  applicablePeriods: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string().oneOf(['WEEKDAYS', 'WEEKENDS', 'PUBLIC_HOLIDAYS', 'EVERYDAY']),
    })
  ),
});

const timeOfUseRequiredPeriodSchema = yup.object().shape({
  fromTime: touTimeSchema,
  toTime: touTimeSchema,
  applicblePeriods: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().oneOf(['WEEKDAYS', 'WEEKENDS', 'PUBLIC_HOLIDAYS', 'EVERYDAY']).required(),
      })
    )
    .min(1, 'At least one applicable period is required'),
});

const rateTierSchema = yup.object().shape({
  tierId: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .optional(),
  rate: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .typeError('This field is required')
    .min(0)
    .max(MAX_DOLLARS_PER_KWH_VALUE, `Must be less than $${MAX_DOLLARS_PER_KWH_VALUE}`)
    .required('Rate value is required'),
});

const rateSchema = yup.object().shape({
  rateId: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .optional(),
  appliesAtAllOtherTimes: yup.boolean(),
  type: yup.string().oneOf(['PEAK', 'OFF-PEAK', 'SHOULDER']),
  tiers: yup.array().of(rateTierSchema),
  timesOfUse: yup.array().when('appliesAtAllOtherTimes', {
    is: true,
    then: yup.array().of(timeOfUseSchema),
    otherwise: yup.array().of(timeOfUseRequiredPeriodSchema),
  }),
});

const touSeasonSchema = yup
  .object()
  .shape({
    name: yup.string().nullable(),
    peakRate: rateSchema.nullable(),
    shoulderRates: yup.array().of(rateSchema),
    offPeakRate: rateSchema.nullable(),
  })
  .test('cover-all-times', 'All times must be covered by rates', validateTimesOfUse as TestFunction)
  .test('minimum-one-rate', 'At least one rate must be specified', validateAtLeastOneRateProvided as TestFunction);

// NOTE: season values and overlap are validated in the season form and its associated schema.
export const touFormSchema = yup.object().shape({
  seasons: yup.array().of(touSeasonSchema),
});
