import { DisplayLabelType } from 'clipsal-cortex-types/src/api/api-rpc';
import React from 'react';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';
import { Heading } from '@chakra-ui/react';
import { GreenOptionsIcon, SingleRateTariffIcon } from 'clipsal-cortex-icons/src/custom-icons';

/**
 * Retrieves the difference in cost between the current bill and the proposed plan.
 * Handles HTML formatting and adding a suffix according to the value's polarity.
 *
 * @param currentBillCost - The cost of the current bill.
 * @param proposedPlanCost - The cost of the proposed plan's bill.
 */
export function getDifferenceBetweenCosts(currentBillCost: number, proposedPlanCost: number): JSX.Element {
  const difference = proposedPlanCost - currentBillCost;

  // Check if the value is negative
  let formattedDifferenceValue = formatDollarsCents(Math.abs(difference));
  let suffixText = '';
  let color = 'inherit';

  // If the value isn't 0, when rounded to 2dp
  if (Math.round((difference + Number.EPSILON) * 100) / 100 !== 0) {
    suffixText = difference > 0 ? 'more' : 'less';
    color = difference > 0 ? '#E20006' : '#17AD33';
  } else {
    formattedDifferenceValue = formatDollarsCents(0);
  }

  return (
    <Heading alignSelf={'center'} style={{ color }} size={'xs'}>
      {formattedDifferenceValue} {suffixText}
    </Heading>
  );
}

export const rpcDisplayLabelTypeToIcon: Record<DisplayLabelType, JSX.Element> = {
  tariff: <SingleRateTariffIcon mr={1} />,
  green_options: <GreenOptionsIcon mr={1} />,
};
