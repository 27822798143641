import { useSelector } from 'react-redux';
import { selectSite } from '../../../site/siteSlice';
import { selectUser } from '../../../user/userSlice';
import { v4 as uuid } from 'uuid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

/**
 * Sends an Amplitude event whenever this page is loaded for an existing tariff.
 * Note that this is the first page a user sees when attempting to edit an existing tariff.
 */
export function useTariffEntryAmplitudeEvent() {
  const site = useSelector(selectSite);
  const user = useSelector(selectUser);
  const { tariffId } = useParams<{ tariffId: string }>();

  useEffect(() => {
    if (tariffId === 'new') {
      const journeyIdentifier = uuid();
      localStorage.setItem('tariffFormJourneyIdentifier', journeyIdentifier);

      const eventProperties: Record<string, string> = {
        'Site ID': site.site_id.toString(),
        'User ID': user.userID.toString(),
        'Tariff Form Journey Identifier': journeyIdentifier,
        'Is New Tariff?': 'false',
      };

      amplitude.logEvent('Tariff Form Started', eventProperties);
    }
  }, [site.site_id, tariffId, user.userID]);
}
