import ReactGA from 'react-ga4';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { SiteDetailData } from 'clipsal-cortex-types/src/api/api-site';
import { get } from '../../common/api/api-helpers';
import packageJson from '../../../package.json';
import * as amplitude from '@amplitude/analytics-browser';
import { IS_PRODUCTION_BUILD, IS_RUNNING_CYPRESS_TESTS } from '../../common/constants';
import { v4 as uuid } from 'uuid';
import { GaOptions } from 'react-ga4/types/ga4';
import { Capacitor } from '@capacitor/core';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

export const APP_VERSION = packageJson.version;

type SiteState = SiteDetailData & { hasDeviceFault: boolean; isWifiConfigModalOpen: boolean };

export type SiteReducers = {
  clearCurrentSite: (state: SiteState, action: PayloadAction<void>) => void;
  updateSiteData: (state: SiteState, action: PayloadAction<Partial<SiteState>>) => void;
};

export const fetchSite = createAsyncThunk<SiteState, number, { state: RootState }>(
  'site/fetchSite',
  async (siteID, { getState }) => {
    const siteData = await get<SiteDetailData>(`/v1/sites/${siteID}`);

    // Update user details in GA, if we're in prod.
    const userData = getState().user;
    const siteBatteries = siteData.devices?.filter((device) => device.device_type === 'BATTERY_PACK');

    if (IS_PRODUCTION_BUILD && userData.role !== 'SUPER_ADMIN' && !IS_RUNNING_CYPRESS_TESTS) {
      const setupGA = () => {
        const trackingId = import.meta.env.VITE_GA_TAG as string;
        let clientId = localStorage.getItem('ga:clientId');

        if (!clientId) {
          // Generate random id for client identifier, put into local storage (should only run on first open).
          clientId = uuid();
          localStorage.setItem('ga:clientId', clientId);
        }

        const gaOptions: GaOptions = {
          userId: siteData.site_id.toString(),
          cookieDomain: 'none',
          clientId: clientId ?? undefined,
        };

        ReactGA.initialize(trackingId, { gaOptions });
        ReactGA.set({
          checkProtocolTask: null,
          user_properties: {
            site_id: siteData.site_id,
            role: userData.role,
            has_battery: !!siteBatteries?.length,
            app_version: APP_VERSION,
          },
        });
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
      };

      /* istanbul ignore if -- @preserve */
      if (Capacitor.getPlatform() === 'ios') {
        const response = await AppTrackingTransparency.requestPermission();
        if (response.status === 'authorized') {
          setupGA();
        }
      } else {
        setupGA();
      }

      // send other user details to amplitude
      const identifyEvent = new amplitude.Identify();
      identifyEvent.set('Site Id', siteData.site_id);
      identifyEvent.set('Role', userData.role);
      identifyEvent.set('Has Battery', !!siteBatteries?.length);
      identifyEvent.set('Tenant Id', userData.tenantID);
      identifyEvent.set('App Version', packageJson.version);
      amplitude.identify(identifyEvent);
    }

    return {
      ...siteData,
      hasDeviceFault: siteData.devices!.some((device) => {
        // "Fault" is a term we use in the front-end to determine if something is wrong with the device (there could
        // be a multitude of reasons why this is the case, e.g. subscription expired).
        const deviceHasFault = !['NORMAL', null].includes(device.operating_state);

        // We only show faults for hybrid inverters, not other types of inverters.
        if (device.device_type === 'INVERTER') {
          // @TODO: we might want to re-instate this at some point.
          return false;
        }

        return deviceHasFault;
      }),
      isWifiConfigModalOpen: false,
    };
  }
);

const initialState: SiteState = {
  site_name: '',
  lat: 0, // Signed float
  lon: 0, // Signed float
  city: '',
  post_code: '',
  state: '',
  country: '',
  address: '',
  timezone: '',
  nmi: '',
  tenant_id: 0,
  distributor_id: 0,
  is_consumption_site: false,
  monitoring_start: '',
  site_id: 0,
  devices: [],
  has_controlled_load: false,
  has_valid_subscription: false,
  has_ac_battery: false,
  has_dc_battery: false,
  commissioning_status: 'PENDING',
  hasDeviceFault: false,
  isWifiConfigModalOpen: false,
  integrations: {
    sensibo: false,
    tesla: false,
    amber: false,
  },
};

export const siteSlice = createSlice<SiteState, SiteReducers>({
  name: 'site',
  initialState,
  reducers: {
    clearCurrentSite: () => {
      return initialState;
    },
    updateSiteData: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSite.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { clearCurrentSite, updateSiteData } = siteSlice.actions;

export const selectSite = (state: RootState) => {
  return state.site;
};

export const selectDevices = (state: RootState) => {
  return state.site.devices;
};

export const selectEvChargers = (state: RootState) => {
  return state.site.devices?.filter((device) => device.device_type === 'EV_CHARGER') || [];
};

export const selectTimezone = (state: RootState) => {
  return state.site.timezone;
};

export default siteSlice.reducer;
