import { SiteDeviceScheduleEvent } from 'clipsal-cortex-types/src/api/api-switch-schedule';

export type SchedulerProps = {
  selectedSwitchId: number;
};

export enum SchedulerRoute {
  View,
  Edit,
}

export enum EditScheduleRoute {
  EditAllSchedules,
  EditDayInterval,
  TimeSelect,
}

// What we use in the UI
export type Schedule = {
  // Note: Because we need to combine schedules, start and end need their own IDs
  startScheduleId: number | null; // Null if not yet saved
  endScheduleId: number | null; // Null if not yet saved
  groupUuid: string | null;
  daysOfWeek: number[];
  startTime: string; // time string
  endTime: string; // time string
  active: boolean;
  groupActions: Array<SiteDeviceScheduleEvent>;
};

export type SolarSpongeDeviceStore = {
  id: number | null;
  siteDeviceId: number | null;
  switchId: number | null;
  dailyMinRuntimeMinutes: number | null;
  exportThresholdKW: number;
  active: boolean;
  priority: number;
};
