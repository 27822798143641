import { Box, Button, Flex, Image, Link, Text, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import schneiderChargePowerCycleImg from '../../../assets/images/se_charge_power_cycle.png';
import TopNav from '../../../common/components/TopNav';
import PageBase from '../../../common/components/PageBase';
import { BOTTOM_NAV_HEIGHT } from '../../../common/constants';
import InfoBottomDrawer from 'clipsal-cortex-ui/src/components/InfoBottomDrawer';
import { useBulkPathSegmentReplace } from '../../../common/hooks/use-bulk-path-segment-replace';

export default function PowerCycleInstructions() {
  const navigate = useNavigate();
  const {
    isOpen: isPowerCycleDrawerOpen,
    onOpen: onOpenPowerCycleDrawer,
    onClose: onClosePowerCycleDrawer,
  } = useDisclosure();
  const [scanWifiQrCode, start] = useBulkPathSegmentReplace(['scan_wifi_qr_code', 'start']);

  return (
    <PageBase h="100%">
      <TopNav title={'Device Setup'} backURL={start} />

      <Flex direction={'column'} align="center" justify={'space-between'} h="100%" maxH={800}>
        <Box px={8} mt={4}>
          <Text fontWeight={'bold'}>Enable Wifi Access Point on Charger</Text>
          <Text mt={4}>
            1. Power cycle your Schneider Charge.
            <Link color="customBlue.300" ml={1} onClick={onOpenPowerCycleDrawer}>
              How to Power Cycle?
            </Link>
          </Text>
          <Text mt={4}>
            2. Once charger is showing solid green, press and hold the PUSH button on the side of the device until it
            starts blinking.
          </Text>
        </Box>
        <Image src={schneiderChargePowerCycleImg} alt="Schneider Charge Device" my={4} w="100%" maxW={200} />
        <Flex direction={'column'} align="center" justify={'center'} width="100%" mb={BOTTOM_NAV_HEIGHT}>
          <Button
            variant="solid"
            size="md"
            onClick={() => navigate(scanWifiQrCode)}
            rounded={50}
            colorScheme="dusk100"
            minW={180}
            fontWeight={500}
            py={6}
            data-testid={'power-cycle-next'}
          >
            Next
          </Button>
        </Flex>
      </Flex>

      <InfoBottomDrawer onClose={onClosePowerCycleDrawer} isOpen={isPowerCycleDrawerOpen} header="How to Power Cycle">
        <InfoBottomDrawer.Content>
          <Text my={4}>
            Turn off the power supply to the charging station, wait for at least 10 seconds, and then turn the power
            supply back on.
          </Text>
          <Text>The device will require another power cycle after 5 minutes as it becomes unavailable.</Text>
        </InfoBottomDrawer.Content>
        <InfoBottomDrawer.ConfirmButton onClick={onClosePowerCycleDrawer} />
      </InfoBottomDrawer>
    </PageBase>
  );
}
