import { Box, Divider, Flex, Heading, Text, useColorModeValue, useTheme } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import MultiToggleSwitch from '../../../../../common/components/MultiToggleSwitch';
import { useTariffFormContext } from '../../tariff-form-context';
import Highcharts from 'highcharts';
import theme from '../../../../../styles/theme';
import ColumnChart from '../ColumnChart';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';

const TIERED_CHART_HEIGHT_PX = 300;
const COLORS = [
  theme.colors.offPeakGreen[500],
  theme.colors.ellipse[500],
  theme.colors.peakRateRed[500],
  theme.colors.consumed[500],
  theme.colors.customBlue[500],
];

export default function TieredRateSummary() {
  const {
    formData: { tieredRates },
  } = useTariffFormContext();
  const theme = useTheme();
  const cardBgColor = useColorModeValue('white', theme.colors.gray[900]);
  const [visibleSeasonIndex, setVisibleSeasonIndex] = useState(0);
  const multiToggleSwitchClassName = useColorModeValue('light-mode-toggle-switch', 'dark-mode-toggle-switch');
  const switchOptions = useMemo(
    () =>
      tieredRates!.seasons.map((season, seasonIndex) => ({
        label: season.name || `Season ${seasonIndex + 1}`,
        value: seasonIndex.toString(),
      })),
    [tieredRates]
  );
  const displayedSeason = tieredRates!.seasons[visibleSeasonIndex] ?? { tiers: [] };

  const stackedTieredSeries = useMemo<Highcharts.SeriesColumnOptions[]>(() => {
    const stacks = displayedSeason.tiers.map((tier, tierIndex) => {
      const data = [];
      const color = COLORS[tierIndex % COLORS.length];
      const isLastTier = tierIndex === displayedSeason.tiers.length - 1;

      data.push({
        color,
        borderColor: cardBgColor,
        borderWidth: 6,
        x: 0,
        y: isLastTier ? displayedSeason.tiers[tierIndex - 1].upperLimitKWh : tier.upperLimitKWh,
        custom: {
          rate: tier.rate,
          upperLimitKWh: tier.upperLimitKWh,
        },
      });

      return {
        data,
        yAxis: 0,
        dataLabels: [
          {
            style: {
              animation: false,
              textOutline: 'none',
              fontSize: '20px',
            },
            enabled: true,
            formatter: function () {
              return formatDollarsCents(this.point.custom.rate ?? 0);
            },
          },
          {
            align: 'left' as const,
            style: {
              animation: false,
              textOutline: 'none',
              fontSize: '14px',
              fontWeight: 'normal',
            },
            enabled: true,
            formatter: function (): string {
              const secondLastTier = displayedSeason.tiers[displayedSeason.tiers.length - 2];
              return tier.upperLimitKWh
                ? `For the ${tierIndex === 0 ? 'first' : 'next'} ${tier.upperLimitKWh} kWh`
                : `After last ${secondLastTier.upperLimitKWh} kWh`;
            },
          },
        ],
        type: 'column' as const,
      };
    });

    const topStack = stacks[stacks.length - 1];

    const placeholderStackTemplate = {
      color: topStack.data[0].color,
      borderColor: cardBgColor,
      borderWidth: 6,
      x: 0,
      y: 0,
      custom: {
        upperLimitKWh: null,
        rate: 0,
      },
    };

    // Adds placeholder stacks for the last value (to visually indicate it has no usage limit)
    stacks.push(
      {
        dataLabels: [],
        data: [
          {
            ...placeholderStackTemplate,
            y: topStack.data[0].y! / 6,
          },
        ],
        yAxis: 0,
        type: 'column' as const,
      },
      {
        dataLabels: [],
        data: [
          {
            ...placeholderStackTemplate,
            y: topStack.data[0].y! / 7,
          },
        ],
        yAxis: 0,
        type: 'column' as const,
      },
      {
        dataLabels: [],
        data: [
          {
            ...placeholderStackTemplate,
            y: topStack.data[0].y! / 8,
          },
        ],
        yAxis: 0,
        type: 'column' as const,
      }
    );

    return stacks;
  }, [displayedSeason.tiers, cardBgColor]);

  return (
    <>
      {tieredRates!.seasons.length > 1 && (
        <Box mt={3} data-testid="season-multi-toggle-switch">
          <MultiToggleSwitch
            className={multiToggleSwitchClassName}
            value={visibleSeasonIndex.toString()}
            onChange={(newSeasonIndex) => {
              setVisibleSeasonIndex(Number(newSeasonIndex));
            }}
            switchOptions={switchOptions}
          />
        </Box>
      )}
      <Box minH={`calc(${TIERED_CHART_HEIGHT_PX}px + 30px)`}>
        <Heading mt={2} size="sm">
          EVERY DAY
        </Heading>
        <ColumnChart series={stackedTieredSeries} height={TIERED_CHART_HEIGHT_PX} />
        {displayedSeason.tiers.map((tier, tierIndex) => {
          let usageLimitCopy = (
            <Box as="span">
              {tierIndex === 0 ? `First` : 'Next'}{' '}
              <Box as="span" fontWeight="bold">
                {tier.upperLimitKWh} kWh
              </Box>
            </Box>
          );

          if (tierIndex === displayedSeason.tiers.length - 1) {
            usageLimitCopy = (
              <Box as="span">
                Usage above the last{' '}
                <Box as="span" fontWeight="bold">
                  {displayedSeason.tiers[tierIndex - 1].upperLimitKWh} kWh
                </Box>
              </Box>
            );
          }

          return (
            <React.Fragment key={`tier-${tierIndex}`}>
              <Flex align="center" mt={2}>
                <Box mr={2} rounded={100} h={8} w={8} bg={COLORS[tierIndex % COLORS.length]} />
                <Box w="100%">
                  <Heading mb={1} size={'sm'}>
                    TIER {tierIndex + 1}
                  </Heading>
                  <Flex align="center" justify="space-between">
                    <Text>{usageLimitCopy}</Text>
                    <Text>
                      ${' '}
                      <Box as="span" fontWeight="bold">
                        {(tier.rate ?? 0).toFixed(2)}
                      </Box>{' '}
                      / kWh
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              {tierIndex !== displayedSeason.tiers.length - 1 && <Divider my={3} />}
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
}
