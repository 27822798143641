import { Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetSchneiderChargeSetup } from './schneiderChargeSetupSlice';
import { IS_NATIVE } from 'clipsal-cortex-utils/src/constants/common-constants';
import { HTTP } from '@awesome-cordova-plugins/http';

export default function SchneiderChargeRoutes() {
  const dispatch = useDispatch();

  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    if (IS_NATIVE) {
      HTTP.setServerTrustMode('nocheck');
      // use json serializer as the server returns json response
      HTTP.setDataSerializer('json');
      // Default is 60 seconds, setting to 30 seconds for faster response
      HTTP.setRequestTimeout(30);
    }

    return () => {
      dispatch(resetSchneiderChargeSetup());
    };
  }, [dispatch]);

  return <Outlet />;
}
