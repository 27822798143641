import React from 'react';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import MobileTopNav from './MobileTopNav';
import { useNavigate } from 'react-router-dom';
import useViewportType from '../hooks/use-viewport-type';

type Props =
  | ({
      backURL: string;
      onClickBack?: never;
    } & CommonProps)
  | ({ onClickBack: () => void; backURL?: never } & CommonProps);

type CommonProps = {
  title: string;
  shouldDisplayTitleOnDesktop?: boolean;
};

// Useful to display a top nav on mobile, or a simple back button on desktop.
export default function TopNav({ backURL, title, shouldDisplayTitleOnDesktop, onClickBack }: Props) {
  const { isDesktopViewport } = useViewportType();
  const navigate = useNavigate();

  const topNavProps = backURL ? { backURL: backURL! } : { onClickBack: onClickBack! };

  return isDesktopViewport ? (
    <Flex align={'center'} w="100%" position={'relative'}>
      <Button
        variant={'ghost'}
        onClick={onClickBack ? onClickBack : () => navigate(backURL!)}
        data-testid="desktop-back-btn"
      >
        <ChevronLeftIcon w={6} h={6} />
        <Text fontWeight={'bolder'}>Back</Text>
      </Button>
      {shouldDisplayTitleOnDesktop && (
        <Heading fontSize={18} position={'absolute'} left={'50%'} transform={'translateX(-50%)'} pb={2}>
          {title}
        </Heading>
      )}
    </Flex>
  ) : (
    <MobileTopNav {...topNavProps} title={title} />
  );
}
