/* eslint-disable react-refresh/only-export-components */
import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { SCHNEIDER_CHARGE_PATH } from './features/site/system-details/constants';

import AmberIntegration from './features/bills/amber/AmberIntegration';
import BasicDetailsForm from './features/bills/tariffs/basic-details/BasicDetailsForm';
import BillsHome from './features/bills/BillsHome';
import ComparePlans from './features/bills/energy-plan/compare-plans/ComparePlans';
import FlatTariffForm from './features/bills/tariffs/rates/flat/FlatTariffForm';
import MobileViewPlanDetail from './features/bills/energy-plan/compare-plans/MobileViewPlanDetail';
import MobileViewPlans from './features/bills/energy-plan/compare-plans/MobileViewPlans';
import OTPVerification from './features/auth/new-user-journey/OTPVerification';
import PowerCycleInstructions from './features/ev-chargers/schneider-charge/PowerCycleInstructions';
import RealTimeTariffForm from './features/bills/tariffs/rates/real-time/RealTimeTariffForm';
import Review from './features/bills/tariffs/review/Review';
import ScanWifiQRCode from './features/ev-chargers/schneider-charge/ScanWifiQRCode';
import SchneiderChargeActivate from './features/ev-chargers/schneider-charge/SchneiderChargeActivate';
import SchneiderChargeEnterPin from './features/ev-chargers/schneider-charge/SchneiderChargeEnterPin';
import SchneiderChargeHomeWifiDetails from './features/ev-chargers/schneider-charge/SchneiderChargeHomeWifiDetails';
import SchneiderChargeNewPin from './features/ev-chargers/schneider-charge/SchneiderChargeNewPin';
import SchneiderChargeRoutes from './features/ev-chargers/schneider-charge/SchneiderChargeRoutes';
import SelectSubscriptionPlan from './features/auth/new-user-journey/SelectSubscriptionPlan';
import SetupGenericFail from './features/ev-chargers/schneider-charge/SetupGenericFail';
import SetupSuccess from './features/ev-chargers/schneider-charge/SetupSuccess';
import SetupUnavailableFail from './features/ev-chargers/schneider-charge/SetupUnavailableFail';
import SiteRedirect from './features/site/SiteRedirect';
import StartSetup from './features/ev-chargers/schneider-charge/StartSetup';
import SubscriptionSuccess from './features/auth/new-user-journey/SubscriptionSuccess';
import TOUForm from './features/bills/tariffs/rates/time-of-use/TOUTariffForm';
import TariffRoutes from './features/bills/tariffs/TariffRoutes';
import TieredTariffForm from './features/bills/tariffs/rates/tiered/TieredTariffForm';

// All first level routes should be lazy loaded.
const AccountSettings = React.lazy(() => import('./features/user/AccountSettings'));
const Login = React.lazy(() => import('./features/auth/login/Login'));
const Logout = React.lazy(() => import('./features/auth/logout/Logout'));
const NewUserJourney = React.lazy(() => import('./features/auth/new-user-journey/NewUserJourney'));
const RouteNotFound = React.lazy(() => import('./common/components/route/RouteNotFound'));
const Site = React.lazy(() => import('./features/site/Site'));
const Sites = React.lazy(() => import('./features/sites/Sites'));
const SubscriptionExpired = React.lazy(() => import('./features/auth/SubscriptionExpired'));
const TeslaOAuthRedirect = React.lazy(() => import('./features/auth/TeslaOAuthRedirect'));

// Side second level routes should be lazy loaded.
const SiteDashboard = React.lazy(() => import('./features/dashboard/SiteDashboard'));
const SiteBills = React.lazy(() => import('./features/bills/SiteBills'));
const SiteLive = React.lazy(() => import('./features/live/SiteLive'));
const SiteNotifications = React.lazy(() => import('./features/notifications/SiteNotifications'));
const SiteSavings = React.lazy(() => import('./features/savings/SiteSavings'));
const SiteUsage = React.lazy(() => import('./features/usage/SiteUsage'));
const SystemDetailsMobile = React.lazy(() => import('./features/site/system-details/SystemDetails'));
const ThirdPartyIntegrationsMobile = React.lazy(
  () => import('./features/dashboard/integrations/ThirdPartyIntegrations')
);

export interface Route {
  path: string;
  element: ReactElement;
  children?: Route[];
  isPublic?: boolean;
}

export const routes: Route[] = [
  {
    element: <SiteRedirect />,
    path: '/',
    isPublic: true,
  },
  {
    element: <Site />,
    path: '/site/:id',
    children: [
      {
        element: <SiteDashboard />,
        path: 'dashboard',
      },
      {
        element: <SiteSavings />,
        path: 'savings',
      },
      {
        element: <SiteUsage />,
        path: 'usage',
      },
      {
        element: <SiteBills />,
        path: 'bills',
        children: [
          {
            element: <BillsHome />,
            path: '',
          },
          {
            element: <ComparePlans />,
            path: 'compare_plans',
          },
          {
            element: <MobileViewPlans />,
            path: 'compare_plans/view_all',
          },
          {
            element: <MobileViewPlanDetail />,
            path: 'compare_plans/view_plan/:planID',
          },
          {
            element: <AmberIntegration />,
            path: 'amber_integration',
          },
          {
            element: <TariffRoutes />,
            path: 'tariffs/:tariffId',
            children: [
              {
                element: <Navigate to="basic-details" />,
                path: '',
              },
              {
                element: <BasicDetailsForm />,
                path: 'basic-details',
              },
              {
                element: <TOUForm />,
                path: 'tou-rate-configuration',
              },
              {
                element: <RealTimeTariffForm />,
                path: 'real-time-rate-configuration',
              },
              {
                element: <FlatTariffForm />,
                path: 'flat-rate-configuration',
              },
              {
                element: <TieredTariffForm />,
                path: 'tiered-rate-configuration',
              },
              {
                element: <Review />,
                path: 'review',
              },
            ],
          },
        ],
      },
      {
        element: <SiteLive />,
        path: 'live',
      },
      {
        element: <SiteNotifications />,
        path: 'notifications',
      },
      {
        element: <SystemDetailsMobile />,
        path: 'system_details',
      },
      {
        element: <SchneiderChargeRoutes />,
        path: `system_details/${SCHNEIDER_CHARGE_PATH}`,
        children: [
          {
            path: '',
            element: <Navigate to="start" />,
          },
          {
            path: 'start',
            element: <StartSetup />,
          },
          {
            path: 'power_cycle_instructions',
            element: <PowerCycleInstructions />,
          },
          {
            path: 'scan_wifi_qr_code',
            element: <ScanWifiQRCode />,
          },
          {
            path: 'enter_pin',
            element: <SchneiderChargeEnterPin />,
          },
          {
            path: 'new_pin',
            element: <SchneiderChargeNewPin />,
          },
          {
            path: 'home_wifi_details',
            element: <SchneiderChargeHomeWifiDetails />,
          },
          {
            path: 'activate',
            element: <SchneiderChargeActivate />,
          },
          {
            path: 'setup_success',
            element: <SetupSuccess />,
          },
          {
            path: 'setup_generic_fail',
            element: <SetupGenericFail />,
          },
          {
            path: 'setup_unavailable_fail',
            element: <SetupUnavailableFail />,
          },
        ],
      },
      {
        element: <ThirdPartyIntegrationsMobile />,
        path: 'integrations',
      },
    ],
  },
  {
    element: <Sites />,
    path: '/sites',
  },
  {
    element: <Login />,
    path: '/login',
    isPublic: true,
  },
  {
    element: <Logout />,
    path: '/logout',
    isPublic: true,
  },
  {
    element: <AccountSettings />,
    path: '/settings',
  },
  {
    element: <NewUserJourney />,
    path: '/new_user_journey',
    isPublic: true,
    children: [
      {
        element: <SelectSubscriptionPlan />,
        path: 'select_subscription_plan',
        isPublic: true,
      },
      {
        element: <SubscriptionSuccess />,
        path: 'success',
        isPublic: true,
      },
      {
        element: <OTPVerification />,
        path: 'otp_verify',
        isPublic: true,
      },
    ],
  },
  {
    element: <SubscriptionExpired />,
    path: '/subscription_expired',
  },
  {
    element: <TeslaOAuthRedirect />,
    path: '/tesla',
    isPublic: true,
  },
  {
    element: <RouteNotFound />,
    path: '*',
  },
];
