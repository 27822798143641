import React, { useCallback, useMemo, useState } from 'react';
import MultiToggleSwitch from '../../../../../common/components/MultiToggleSwitch';
import { useTariffFormContext } from '../../tariff-form-context';
import { Box, Flex, Heading, useColorModeValue, Text, BoxProps, AlertIcon, Alert } from '@chakra-ui/react';
import { TOURate } from '../../rates/time-of-use/types';
import { capitalizeFirst, DateStringFormatType, formatNiceDate } from 'clipsal-cortex-utils/src/formatting/formatting';
import { TOU_RATE_TYPE_TO_COLOR, TOU_RATE_TYPE_TO_VALUE } from './constants';
import TimeSpanChart from '../TimeSpanChart';
import useRatesByPeriod from './hooks';
import { convertTimeToDate } from '../../rates/time-of-use/utils';
import Highcharts from 'highcharts';

export default function TOURateSummary() {
  const {
    formData: { touRates },
  } = useTariffFormContext();

  const [visibleSeasonIndex, setVisibleSeasonIndex] = useState(0);
  const multiToggleSwitchClassName = useColorModeValue('light-mode-toggle-switch', 'dark-mode-toggle-switch');
  const switchOptions = useMemo(
    () =>
      touRates!.seasons.map((season, seasonIndex) => ({
        label: season.name || `Season ${seasonIndex + 1}`,
        value: seasonIndex.toString(),
      })),
    [touRates]
  );
  const displayedSeason = touRates!.seasons[visibleSeasonIndex];

  const seasonDateRange = useMemo(() => {
    const fromDate = new Date(2021, displayedSeason.fromMonth, displayedSeason.fromDate);
    const toDate = new Date(2021, displayedSeason.toMonth, displayedSeason.toDate);

    return `From ${formatNiceDate(fromDate, DateStringFormatType.DayLongMonthNoYear)} to ${formatNiceDate(
      toDate,
      DateStringFormatType.DayLongMonthNoYear
    )}`;
  }, [displayedSeason]);

  const allRatesFlattened = useMemo<TOURate[]>(() => {
    const allRates = [];
    if (displayedSeason?.peakRate) allRates.push(displayedSeason.peakRate);
    allRates.push(...displayedSeason.shoulderRates);
    if (displayedSeason?.offPeakRate) allRates.push(displayedSeason.offPeakRate);
    return allRates;
  }, [displayedSeason]);

  const doesRateIncludePublicHolidayCoverage = useMemo<boolean>(
    () =>
      !!allRatesFlattened.find((rate) =>
        rate.timesOfUse.find((t) => t.applicablePeriods.find(({ value }) => value === 'PUBLIC_HOLIDAYS'))
      ),
    [allRatesFlattened]
  );

  // @TODO: rates which 'apply at all other times' still display for a period type, even if they do not have rates
  //        during that period. likely unnoticeable by users, but still potentially confusing
  const ratesApplicableOnWeekends = useMemo(
    () =>
      allRatesFlattened.filter((rate) =>
        rate.timesOfUse.find((t) => t.applicablePeriods.find(({ value }) => ['EVERYDAY', 'WEEKENDS'].includes(value)))
      ),
    [allRatesFlattened]
  );
  const ratesApplicableOnWeekdays = useMemo(
    () =>
      allRatesFlattened.filter((rate) =>
        rate.timesOfUse.find((t) => t.applicablePeriods.find(({ value }) => ['EVERYDAY', 'WEEKDAYS'].includes(value)))
      ),
    [allRatesFlattened]
  );
  const ratesApplicableOnPublicHolidays = useMemo(
    () =>
      allRatesFlattened.filter(
        (rate) =>
          rate.appliesAtAllOtherTimes ||
          rate.timesOfUse.find((t) => t.applicablePeriods.find(({ value }) => value === 'PUBLIC_HOLIDAYS'))
      ),
    [allRatesFlattened]
  );

  const ratesByPeriod = useRatesByPeriod(allRatesFlattened);
  const getSeriesByPeriodType = useCallback(
    (periodType: 'weekdays' | 'weekends' | 'publicHolidays') => {
      const rateApplicableAtAllOtherTimes = ratesByPeriod[periodType].find(
        (timeOfUse) => timeOfUse.appliesAtAllOtherTimes
      );
      // Used to prevent further iteration on the data array when filling gaps, instead it's looked up in this map.
      const timestampToExistingDataMap: Record<number, boolean> = {};

      // Normalize all times of use in terms of minutes on the X axis (1 - 1440)
      const series = ratesByPeriod[periodType]
        .filter((timeOfUse) => !timeOfUse.appliesAtAllOtherTimes)
        .flatMap((timeOfUse) => {
          const data = [];
          const fromTime = convertTimeToDate(timeOfUse.fromTime);
          const toTime = convertTimeToDate(timeOfUse.toTime);

          // The only time this can happen now, is when the 'to time' is midnight, so we can safely increment the date.
          if (toTime.getTime() <= fromTime.getTime()) {
            toTime.setDate(toTime.getDate() + 1);
          }

          const differenceInMinutes = (toTime.getTime() - fromTime.getTime()) / 1000 / 60;
          const newData = Array.from(Array(differenceInMinutes)).map((_, minuteIndex) => {
            const minuteValue = minuteIndex + 1;
            const timestamp = fromTime.getTime() + minuteValue * 1000 * 60;
            timestampToExistingDataMap[timestamp] = true;

            return {
              color: TOU_RATE_TYPE_TO_COLOR[timeOfUse.type],
              x: timestamp,
              y: TOU_RATE_TYPE_TO_VALUE[timeOfUse.type],
            };
          });
          data.push(...newData);

          return data;
        });

      // Fill in the gaps by iterating through intervals from start -> finish and adding entries for each minute
      if (rateApplicableAtAllOtherTimes) {
        const fromTime = new Date(2021, 0, 1, 0, 0, 0, 0);
        const toTime = new Date(2021, 0, 2, 0, 0, 0, 0);

        while (fromTime.getTime() < toTime.getTime()) {
          if (!timestampToExistingDataMap[new Date(fromTime).getTime()]) {
            series.push({
              color: TOU_RATE_TYPE_TO_COLOR[rateApplicableAtAllOtherTimes.type],
              x: new Date(fromTime).getTime(),
              y: TOU_RATE_TYPE_TO_VALUE[rateApplicableAtAllOtherTimes.type],
            });
          }
          fromTime.setMinutes(fromTime.getMinutes() + 1);
        }

        // Re-sort series
        series.sort((a, b) => a.x - b.x);
      }

      return series;
    },
    [ratesByPeriod]
  );

  const weekdayChartSeries = useMemo<Highcharts.SeriesOptionsType[]>(() => {
    return [
      {
        data: getSeriesByPeriodType('weekdays'),
        yAxis: 0,
        type: 'column',
      },
    ];
  }, [getSeriesByPeriodType]);
  const weekendChartSeries = useMemo<Highcharts.SeriesOptionsType[]>(() => {
    return [
      {
        data: getSeriesByPeriodType('weekends'),
        yAxis: 0,
        type: 'column',
      },
    ];
  }, [getSeriesByPeriodType]);
  const publicHolidayChartSeries = useMemo<Highcharts.SeriesOptionsType[]>(() => {
    return [
      {
        data: getSeriesByPeriodType('publicHolidays'),
        yAxis: 0,
        type: 'column',
      },
    ];
  }, [getSeriesByPeriodType]);

  return (
    <>
      {touRates!.seasons.length > 1 && (
        <>
          <Box mt={3} data-testid="season-multi-toggle-switch">
            <MultiToggleSwitch
              className={multiToggleSwitchClassName}
              value={visibleSeasonIndex.toString()}
              onChange={(newSeasonIndex) => {
                setVisibleSeasonIndex(Number(newSeasonIndex));
              }}
              switchOptions={switchOptions}
            />
          </Box>

          <Box mt={3}>
            <Alert status="info" variant="left-accent">
              <AlertIcon />
              {seasonDateRange}
            </Alert>
          </Box>
        </>
      )}

      <Box minH="230px">
        <Heading mt={5} size="sm">
          WEEKDAYS
        </Heading>
        <TimeSpanChart series={weekdayChartSeries} />
        <RateLegend mb={3} rates={ratesApplicableOnWeekdays} />

        <Heading mt={5} size="sm">
          WEEKENDS
        </Heading>
        <TimeSpanChart series={weekendChartSeries} />
        <RateLegend rates={ratesApplicableOnWeekends} />

        {doesRateIncludePublicHolidayCoverage && (
          <>
            <Heading mt={5} size="sm">
              PUBLIC HOLIDAYS
            </Heading>
            <TimeSpanChart series={publicHolidayChartSeries} />
            <RateLegend rates={ratesApplicableOnPublicHolidays} />
          </>
        )}
      </Box>
    </>
  );
}

function RateLegend({ rates, ...rest }: { rates: TOURate[] } & BoxProps) {
  return (
    <Box mx={2} w={['100%', '100%', '80%', '40%']} {...rest}>
      {rates.map((rate, rateIndex) => {
        return (
          <Flex mt={rateIndex === 0 ? 0 : 2} key={`rate-${rateIndex}`} align="center" justify="space-between">
            <Flex align="center">
              <Box rounded={50} mr={2} h={4} w={4} bg={TOU_RATE_TYPE_TO_COLOR[rate.type]} />
              <Text fontWeight="bold" color={TOU_RATE_TYPE_TO_COLOR[rate.type]}>
                {capitalizeFirst(rate.type.toLowerCase())}
              </Text>
            </Flex>
            <Flex mr={3}>
              <Text fontWeight="bold">${(rate.tiers[0]?.rate ?? 0).toFixed(2)}</Text>
              <Text ml={1}>/ kWh</Text>
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
}
