import React, { useMemo, useState } from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Progress,
  SimpleGrid,
  Skeleton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { formatDollarsCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';
import { formatNiceDateFromString } from 'clipsal-cortex-utils/src/formatting/formatting';
import {
  calculateDifferenceBetweenDates,
  convertDateStringToDateObject,
} from 'clipsal-cortex-utils/src/calculations/date-utils';
import { ChevronUpIcon } from '@chakra-ui/icons';
import { selectSite } from '../site/siteSlice';
import { useViewportType } from '../../common/hooks/use-viewport-type';
import theme from '../../styles/theme';
import Card from 'clipsal-cortex-ui/src/components/card/Card';
import { useGetSiteBillForecastQuery, useGetSiteBillPeriodCostsQuery, useGetSiteBillsQuery } from './billsApi';
import GenericErrorCard from '../../common/components/GenericErrorCard';

interface Props {
  isParentLoaded: boolean;
}

export default function CurrentBillPeriod({ isParentLoaded }: Props) {
  const site = useSelector(selectSite);
  const {
    data: billForecast,
    isLoading: isBillForecastLoading,
    isError: isBillForecastError,
    error: billForecastError,
  } = useGetSiteBillForecastQuery(site.site_id);
  const {
    data: costs,
    isLoading: isCostsLoading,
    isError: isCostsError,
  } = useGetSiteBillPeriodCostsQuery(
    { siteId: site.site_id, startDate: billForecast?.start_date ?? null, endDate: billForecast?.end_date ?? null },
    { skip: isBillForecastLoading || isBillForecastError }
  );
  const { data: bills, isLoading: isBillsLoading, isError: isBillsError } = useGetSiteBillsQuery(site.site_id);
  const isLoaded = !isBillForecastLoading && !isCostsLoading && !isBillsLoading;
  const isError = isCostsError || isBillsError || isBillsError || isBillForecastError;
  const siteMonitoringStartDate = site.monitoring_start
    ? convertDateStringToDateObject(site.monitoring_start)
    : new Date();
  const { isDesktopViewport } = useViewportType();
  const [isExpanded, setExpanded] = useState(isDesktopViewport);
  const progressColorScheme = useColorModeValue('customBlack', 'gray');
  const headingColor = useColorModeValue('rgba(0, 0, 0, 0.5)', 'white');

  const recentBillEndDate = bills?.length ? convertDateStringToDateObject(bills[0]?.end_date) : new Date();
  recentBillEndDate.setDate(recentBillEndDate.getDate() + 1);

  const { daysSinceBillStart, daysUntilBillEnd, billPeriodProgressValue } = useMemo(() => {
    if (!isLoaded || !isParentLoaded || isError) {
      return {
        daysSinceBillStart: 0,
        daysUntilBillEnd: 0,
        billPeriodProgressValue: 0,
      };
    }

    const daysSinceBillStart =
      calculateDifferenceBetweenDates(new Date(billForecast?.start_date ?? ''), new Date()) + 1;

    return {
      daysSinceBillStart,
      daysUntilBillEnd: calculateDifferenceBetweenDates(new Date(), new Date(billForecast!.end_date)),
      billPeriodProgressValue: Math.min(Math.round((daysSinceBillStart / billForecast!.num_days) * 100), 100),
    };
  }, [billForecast, isLoaded, isParentLoaded, isError]);

  if (isError) return <GenericErrorCard>{billForecastError?.message}</GenericErrorCard>;

  return (
    <Card data-testid="bill-period-and-forecast">
      <>
        <Heading size={'md'} className={'tour-bills-step-two'}>
          Current Bill Period
        </Heading>

        <Box my={5}>
          <Flex justify={'space-between'}>
            <Skeleton isLoaded={isLoaded && isParentLoaded}>
              <Box>{!!billForecast?.start_date && formatNiceDateFromString(billForecast.start_date)}</Box>
            </Skeleton>
            <Skeleton isLoaded={isLoaded && isParentLoaded}>
              <Box>{!!billForecast?.end_date && formatNiceDateFromString(billForecast.end_date)}</Box>
            </Skeleton>
          </Flex>

          <Skeleton isLoaded={isLoaded && isParentLoaded}>
            <Progress
              my={2}
              rounded={20}
              width={'100%'}
              colorScheme={progressColorScheme}
              value={billPeriodProgressValue}
            />
          </Skeleton>

          <Flex justify={'space-between'}>
            <Skeleton isLoaded={isLoaded && isParentLoaded}>
              <Flex align={'flex-end'}>
                <Heading mr={1} size={'md'}>
                  {daysSinceBillStart}
                </Heading>
                <Text>days</Text>
              </Flex>
            </Skeleton>

            <Skeleton isLoaded={isLoaded && isParentLoaded}>
              <Flex align={'flex-end'}>
                <Heading mr={1} size={'md'}>
                  {daysUntilBillEnd}
                </Heading>
                <Text>days left</Text>
              </Flex>
            </Skeleton>
          </Flex>
        </Box>

        {isLoaded && daysUntilBillEnd === 0 && (
          <Alert mb={4} py={3} rounded={10} status="warning">
            <AlertIcon color={theme.colors.customYellow[500]} />
            New bill expected
          </Alert>
        )}

        {isLoaded &&
          (!bills!.length || calculateDifferenceBetweenDates(siteMonitoringStartDate, recentBillEndDate) < 0) && (
            <Alert mb={4} py={3} rounded={10} status="warning">
              <AlertIcon color={theme.colors.customYellow[500]} />
              Bill period beginning from the monitoring start date of the Clipsal Cortex app
            </Alert>
          )}

        <Skeleton isLoaded={isLoaded && isParentLoaded}>
          <SimpleGrid my={3} columns={3}>
            <Flex textAlign={'center'} direction={'column'} align={'center'} justify={'center'}>
              <Text fontSize={'sm'} color={headingColor}>
                Bill forecast
              </Text>
              <Text fontSize={'xl'}>{formatDollarsCents(billForecast?.forecast ?? 0)}</Text>
            </Flex>
            <Flex textAlign={'center'} direction={'column'} align={'center'} justify={'center'}>
              <Text fontSize={'sm'} color={headingColor}>
                Cost per day
              </Text>
              <Text fontSize={'xl'}>{formatDollarsCents(billForecast?.average_cost_per_day ?? 0)}</Text>
            </Flex>
            <Flex textAlign={'center'} direction={'column'} align={'center'} justify={'center'}>
              <Text fontSize={'sm'} color={headingColor}>
                Cost to date
              </Text>
              <Heading size={'md'}>{formatDollarsCents(billForecast?.total_cost_since_last_bill ?? 0)}</Heading>
            </Flex>
          </SimpleGrid>
        </Skeleton>

        <Collapse in={isExpanded} unmountOnExit={true} data-testid="current-bill-breakdown">
          <Skeleton isLoaded={isLoaded && isParentLoaded}>
            <Flex py={4} justify={'space-between'}>
              <Heading color={theme.colors.headingGrey[500]} size={'sm'}>
                Bought energy
              </Heading>
              <Text>{formatDollarsCents(costs?.bought ?? 0)}</Text>
            </Flex>
          </Skeleton>

          {/* If controlled load is `null`, don't show it. Note that we still display it if it's 0. */}
          {costs?.controlledLoad !== null && costs?.controlledLoad !== undefined && (
            <Skeleton isLoaded={isLoaded && isParentLoaded}>
              <Flex py={4} justify={'space-between'}>
                <Heading color={theme.colors.headingGrey[500]} size={'sm'}>
                  Controlled load
                </Heading>
                <Text>{formatDollarsCents(costs.controlledLoad)}</Text>
              </Flex>
            </Skeleton>
          )}

          <Skeleton isLoaded={isLoaded && isParentLoaded}>
            <Flex py={4} justify={'space-between'}>
              <Heading color={theme.colors.headingGrey[500]} size={'sm'}>
                Supply charges
              </Heading>
              <Text>{formatDollarsCents(costs?.supplyCharge ?? 0)}</Text>
            </Flex>
          </Skeleton>

          <Skeleton isLoaded={isLoaded && isParentLoaded}>
            <Flex py={4} justify={'space-between'}>
              <Heading color={theme.colors.headingGrey[500]} size={'sm'}>
                Solar sold*
              </Heading>
              <Text>{formatDollarsCents(costs?.sold ?? 0)}</Text>
            </Flex>
          </Skeleton>

          <Skeleton isLoaded={isLoaded && isParentLoaded}>
            <Flex py={4} justify={'space-between'}>
              <Text color={theme.colors.headingGrey[500]}>
                *GST not applicable. All other items are inclusive of GST.
              </Text>
            </Flex>
          </Skeleton>
        </Collapse>

        {!isDesktopViewport && (
          <Flex mt={2} justify={'center'} align={'center'} color={'customBlue.500'}>
            <Button
              mt={3}
              rightIcon={
                <ChevronUpIcon
                  transform={isExpanded ? undefined : 'rotate(-180deg)'}
                  transition={'all 300ms ease-in-out'}
                  cursor={'pointer'}
                  w={'26px'}
                  h={'26px'}
                />
              }
              variant={'ghost'}
              display={'flex'}
              py={2}
              onClick={() => setExpanded(!isExpanded)}
              data-testid="show-breakdown-btn"
              aria-label={'Show breakdown'}
            >
              <Text fontWeight={'bolder'}>Show breakdown</Text>{' '}
            </Button>
          </Flex>
        )}
      </>
    </Card>
  );
}
