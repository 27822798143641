import { getStringifiedQueryParams } from 'clipsal-cortex-utils/src/formatting/query-params';
import { baseApi } from '../../../app/services/baseApi';

export type AmberSiteQueryParams = {
  limit: number;
  offset: number;
};

type AmberSite = {
  id: 'string';
  nmi: 'string';
};

export type AmberConnection = {
  user_id: number;
  row_created: string;
  row_updated: string;
};

type ConnectedAmberSite = {
  site_id: number;
  amber_site_id: string;
  row_created: string;
  row_updated: string;
};

export const amberApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAmberApiSites: build.query<AmberSite[] | '', Partial<AmberSiteQueryParams> | void>({
      query: (params) => {
        let uri = `/v1/amber_api_sites`;
        if (params) uri += `&${getStringifiedQueryParams<string>(params)}`;
        return uri;
      },
      providesTags: ['AmberSites'],
    }),
    getAmberConnection: build.query<AmberConnection, void>({
      query: () => '/v1/amber_connection',
      providesTags: ['AmberConnection'],
    }),
    postAmberConnection: build.mutation<AmberConnection, string>({
      query: (token) => ({
        url: '/v1/amber_connection',
        method: 'POST',
        body: { token },
      }),
      invalidatesTags: ['AmberConnection'],
    }),
    patchAmberConnection: build.mutation<AmberConnection, string>({
      query: (token) => ({
        url: '/v1/amber_connection',
        method: 'PATCH',
        body: { token },
      }),
      invalidatesTags: ['AmberConnection'],
    }),
    deleteAmberConnection: build.mutation<AmberConnection, void>({
      query: () => ({
        url: '/v1/amber_connection',
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['AmberConnection', 'AmberSites'],
    }),
    postAmberSite: build.mutation<ConnectedAmberSite, { siteId: number; amberSiteId: string }>({
      query: ({ siteId, amberSiteId }) => ({
        url: `/v1/sites/${siteId}/amber_site`,
        method: 'POST',
        body: { amber_site_id: amberSiteId },
      }),
    }),
    deleteAmberSite: build.mutation<ConnectedAmberSite, number>({
      query: (siteId) => ({
        url: `/v1/sites/${siteId}/amber_site`,
        method: 'DELETE',
        body: {},
      }),
    }),
  }),
});

export const {
  useGetAmberApiSitesQuery,
  useGetAmberConnectionQuery,
  usePostAmberConnectionMutation,
  usePatchAmberConnectionMutation,
  useDeleteAmberConnectionMutation,
  usePostAmberSiteMutation,
  useDeleteAmberSiteMutation,
} = amberApi;
