import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import schneiderChargeImg from '../../../assets/images/se_charge.png';
import TopNav from '../../../common/components/TopNav';
import PageBase from '../../../common/components/PageBase';
import { BOTTOM_NAV_HEIGHT } from '../../../common/constants';
import { WarningIcon } from '@chakra-ui/icons';
import { useBulkPathSegmentReplace } from '../../../common/hooks/use-bulk-path-segment-replace';

export default function SetupGenericFail() {
  const navigate = useNavigate();
  const [activate] = useBulkPathSegmentReplace(['activate']);

  return (
    <PageBase h="100%">
      <TopNav title={'Device Setup'} backURL={activate} />

      <Flex direction={'column'} align="center" justify={'space-between'} h="100%" maxH={800}>
        <Box px={8} mt={4}>
          <Text fontWeight={'bold'}>Something went wrong</Text>
          <Text>Please try to connect your app again.</Text>
          <Text>The app couldn't connect to the EV charger, but you can still charge your Electric Vehicle.</Text>
        </Box>
        <Box position={'relative'}>
          <Image src={schneiderChargeImg} alt="Schneider Charge Setup Successful" my={4} w="100%" maxW={200} />
          <WarningIcon position={'absolute'} right={-6} bottom={0} w={16} h={16} color="#F17A6A" />
        </Box>
        <Flex direction={'column'} align="center" justify={'center'} width="100%" mb={BOTTOM_NAV_HEIGHT}>
          <Button
            variant="solid"
            size="md"
            rounded={50}
            colorScheme="dusk100"
            minW={180}
            px={8}
            fontWeight={500}
            py={6}
            mb={6}
            onClick={() => navigate(activate)}
          >
            Try Again
          </Button>
        </Flex>
      </Flex>
    </PageBase>
  );
}
