import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

type CircularLoaderProps = BoxProps & {
  trackWidth?: number;
  speedInMs?: number;
};
const CircularLoader = ({ trackWidth = 12, speedInMs = 2500, ...boxProps }: CircularLoaderProps) => {
  const svgViewBoxWidth = 200 + (trackWidth - 8) * 2;
  const svgOffset = 8 - trackWidth;

  return (
    <Box maxW="100vw" overflow={'hidden'} {...boxProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox={`${svgOffset} ${svgOffset} ${svgViewBoxWidth} ${svgViewBoxWidth}`}
        fill="none"
        color="currentColor"
      >
        <defs>
          <linearGradient id="spinner-secondHalf">
            <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
          <linearGradient id="spinner-firstHalf">
            <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
            <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
          </linearGradient>
        </defs>

        <g
          strokeWidth={trackWidth}
          style={{
            animation: `spin ${speedInMs}ms linear infinite`,
            transformOrigin: '50% 50%',
            transformBox: 'fill-box',
          }}
        >
          <path stroke="url(#spinner-secondHalf)" d={`M 4 100 A 96 96 0 0 1 196 100`} />
          <path stroke="url(#spinner-firstHalf)" d={`M 196 100 A 96 96 0 0 1 4 100`} />
          <path stroke="currentColor" strokeLinecap="round" d="M 4 100 A 96 96 0 0 1 4 98" />
        </g>
      </svg>
    </Box>
  );
};

export default CircularLoader;
