import React from 'react';
import { Center, Text } from '@chakra-ui/react';
import { Capacitor } from '@capacitor/core';

export default function DemoLoginBanner() {
  return (
    <Center
      py={1}
      background="primaryBranding.500"
      color="white"
      position="fixed"
      w="100vw"
      cursor={'pointer'}
      top={'env(safe-area-inset-top)'}
      // chakra ui modal zIndex is 1400, so we need to
      // set this more than that to make sure it's on top of everything
      zIndex={1401}
      onClick={() => {
        localStorage.removeItem('isDemoLogin');
        let redirectUrl = '/';
        if (localStorage.getItem('isDemoLoginFromSubscriptionPlansPage')) {
          localStorage.removeItem('isDemoLoginFromSubscriptionPlansPage');
          redirectUrl = '/new_user_journey/select_subscription_plan';
        }

        window.location.replace(redirectUrl);
      }}
    >
      <Text fontWeight="bold">Demo Mode</Text>
      <Text ml={1}>
        -{' '}
        {
          /* istanbul ignore next -- @preserve */
          Capacitor.isNativePlatform() ? 'Touch' : 'Click'
        }{' '}
        to exit
      </Text>
    </Center>
  );
}
