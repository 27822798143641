import { useEffect, useRef } from 'react';

/**
 * Runs a function on mount, but not on subsequent renders, regardless of strict mode.
 * This is not a general purpose useEffect substitute and should be used with care.
 *
 * @param fn The function to run on mount. This function must not depend on any changeable state.
 * @returns void
 * @example
 * useOnMount(() => {
 *  console.log('Mounted!');
 * });
 */
export default function useOnMount(fn: () => void) {
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      fn();
      mounted.current = true;
    }
  }, [fn]);
}
