import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import { IS_IOS } from 'clipsal-cortex-utils/src/constants/common-constants';
import { Browser } from '@capacitor/browser';

export function useDeepLinkSetup() {
  const navigate = useNavigate();
  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    if (Capacitor.isNativePlatform()) {
      CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        const params = new URLSearchParams(event.url.split('?')?.[1] ?? '');
        const code = params.get('code');
        const state = params.get('state');

        // Assume an OAuth redirect if state and code are found in query params
        if (code && state) {
          navigate(event.url.split('pulse:/')[1]);
          if (IS_IOS) Browser.close();
        } else {
          // @TODO: figure out why deep link URLs ever needed to be structured like this? universal links?
          // In IOS this is also fired during bill sharing through other apps or filesystem
          // so ensuring this function does not get invoked in such cases
          if (!event.url.includes('clipsalcortex.com')) return;

          const deepLinkUrl = event.url.split('.com').pop();
          if (deepLinkUrl) {
            navigate(deepLinkUrl);
            // Since we dont have exact routing for site/:id
            // we need to reload the site to achieve the url effect
            window.location.reload();
          }
        }
      });
    }
  }, [navigate]);
}
