import * as yup from 'yup';
import { validateSeasons } from './utils';
import { TestFunction } from 'yup/lib/util/createValidation';

export const schema = yup.object().shape({
  seasons: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Season name is required.'),
        fromDate: yup.date().required('Season from date is required.'),
        toDate: yup.date().required('Season to date is required.'),
      })
    )
    .test(
      'cover-entire-year',
      'Seasons must cover the entire year without overlap or gaps',
      validateSeasons as TestFunction
    )
    .min(1, 'At least one season must exist.')
    .required(),
});
