import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TOUTariffFormData } from './types';
import TimeSelectSlider from './TimeSelectSlider';

type Props = {
  isError: boolean;
  formPath: ControlledTimeSelectButtonFormPath;
  type: 'START' | 'END';
  isDisabled?: boolean;
  ['data-testid']?: string;
};

export type ControlledTimeSelectButtonFormPath =
  | `seasons.${number}.shoulderRates.${number}.timesOfUse.${number}.${'fromTime' | 'toTime'}`
  | `seasons.${number}.peakRate.timesOfUse.${number}.${'fromTime' | 'toTime'}`
  | `seasons.${number}.offPeakRate.timesOfUse.${number}.${'fromTime' | 'toTime'}`;

export default function ControlledTimeSelectButton(props: Props) {
  const { formPath, type, isError, isDisabled } = props;
  const { control } = useFormContext<TOUTariffFormData>();

  return (
    <Controller
      name={formPath}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TimeSelectSlider
          buttonProps={{
            borderColor: isError ? 'customRed.500' : `rgba(60, 60, 67, 0.3)`,
            isDisabled,
            'data-testid': props['data-testid'],
          }}
          data-testid={props['data-testid']}
          onChange={onChange}
          value={value}
          type={type}
        />
      )}
    />
  );
}
