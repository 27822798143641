import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { MenuProps, Select, chakraComponents, GroupBase, OptionProps, SingleValueProps } from 'chakra-react-select';
import { DiscountSelectOption, DiscountsFormData } from './types';
import { Box, Flex, Image } from '@chakra-ui/react';
import { DISCOUNT_TYPE_TO_IMAGE } from './constants';

type Props = {
  control: Control<DiscountsFormData>;
  discountIndex: number;
  isDisabled?: boolean;
  options: DiscountSelectOption[];
  ['data-testid']?: string;
};

export default function DiscountSelect(props: Props) {
  const { isDisabled, control, discountIndex, options } = props;

  return (
    <Controller
      control={control}
      name={`discounts.${discountIndex}.type`}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <Box data-testid={`discount-${discountIndex}-type-select-input`}>
            <Select
              ref={ref}
              components={{
                // Overrides the ID for the menu and options to facilitate testing
                Menu: (menuProps: MenuProps<DiscountSelectOption, true, GroupBase<DiscountSelectOption>>) => (
                  <chakraComponents.Menu className="discount-select-menu portal-react-select-menu" {...menuProps} />
                ),
                SingleValue: (
                  singleValueProps: SingleValueProps<DiscountSelectOption, true, GroupBase<DiscountSelectOption>>
                ) => (
                  <chakraComponents.SingleValue {...singleValueProps}>
                    <Flex align="center">
                      <Image
                        mr={2}
                        w={8}
                        h={8}
                        src={DISCOUNT_TYPE_TO_IMAGE[singleValueProps.data.value]}
                        alt={`Discount ${singleValueProps.data.value}`}
                      />{' '}
                      {singleValueProps.data.label}
                    </Flex>
                  </chakraComponents.SingleValue>
                ),
                Option: (optionProps: OptionProps<DiscountSelectOption, true, GroupBase<DiscountSelectOption>>) => (
                  <chakraComponents.Option
                    className={`discount-select-option-${optionProps.data.value.toLowerCase().replaceAll('_', '-')}`}
                    {...optionProps}
                  >
                    <Image
                      mr={2}
                      w={8}
                      h={8}
                      src={DISCOUNT_TYPE_TO_IMAGE[optionProps.data.value]}
                      alt={`Discount ${optionProps.data.value}`}
                    />{' '}
                    {optionProps.label}
                  </chakraComponents.Option>
                ),
              }}
              isDisabled={isDisabled}
              onChange={onChange}
              isSearchable={false}
              placeholder={'Select a discount type..'}
              onBlur={onBlur}
              // Not specifying portal target can cause the menu to become hidden.
              menuPortalTarget={document.body}
              options={options}
              value={value}
              useBasicStyles
              // Allows the menu (technically rendered underneath the modal) to be visible over the modal, and to
              // ensure the menu is not rendered inside the modal itself (which can cause it to be scrollable)
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </Box>
        );
      }}
    />
  );
}
