import { Box, Button, Flex, Image, Text, useToast, VStack } from '@chakra-ui/react';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import schneiderChargeWifiImg from '../../../assets/images/se_charge_wifi.png';
import TopNav from '../../../common/components/TopNav';
import PageBase from '../../../common/components/PageBase';
import { BOTTOM_NAV_HEIGHT } from '../../../common/constants';
import CircularLoader from '../../../common/components/CircularLoader';
import { didUserGrantBarcodePermission } from 'clipsal-cortex-utils/src/common';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { IS_NATIVE } from 'clipsal-cortex-utils/src/constants/common-constants';
import { Wifi } from '@capacitor-community/wifi/src';
import { useBulkPathSegmentReplace } from '../../../common/hooks/use-bulk-path-segment-replace';

export default function ScanWifiQRCode() {
  const navigate = useNavigate();
  const [isConnecting, setIsConnecting] = useState(false);
  const toast = useToast();
  const [enterPin, powerCycleInstructions] = useBulkPathSegmentReplace(['enter_pin', 'power_cycle_instructions']);

  const showInvalidQRCodeToast = useCallback(() => {
    document.body.classList.remove('qrscanner');
    toast({
      title: 'Invalid QR code!',
      description: 'Please try again! If issue persists, please contact support.',
      status: 'error',
      isClosable: true,
    });
  }, [toast]);

  const handleStartQRCodeScan = useCallback(async () => {
    try {
      const hasPermission = await didUserGrantBarcodePermission();

      /* istanbul ignore next -- @preserve */
      if (hasPermission) {
        await BarcodeScanner.hideBackground();
        document.body.classList.add('qrscanner');
        const result = await BarcodeScanner.startScan();
        if (result.hasContent) {
          // qr content sample:
          // WIFI:T:WPA;S:Schneider Charge SN2310170039;P:*jxlnu#UGAKZ3001028^;;
          const barcodeContent = result.content;
          if (!barcodeContent) {
            showInvalidQRCodeToast();
            return;
          }

          const splittedUrl = barcodeContent.split(';');
          const ssid = splittedUrl[1].split(':')[1];
          const password = splittedUrl[2].split(':')[1];

          if (!ssid || !password) {
            showInvalidQRCodeToast();
            return;
          }

          setIsConnecting(true);
          document.body.classList.remove('qrscanner');

          try {
            await Wifi.connect({ ssid, password, isHiddenSsid: false });
            toast({
              title: 'Successfully connected to wifi!',
              status: 'success',
              isClosable: true,
            });
            navigate(enterPin);
          } catch (error) {
            console.error('Error connecting to wifi', error);
            toast({
              title: 'Error connecting to wifi!',
              description: 'Please try again! If issue persists, please contact support.',
              status: 'error',
              isClosable: true,
            });
          }
        } else {
          console.error('No content from QR Code');
          showInvalidQRCodeToast();
        }
      }
    } catch (error) {
      console.error('Error scanning QR Code', error);
      showInvalidQRCodeToast();
    }
    setIsConnecting(false);
  }, [showInvalidQRCodeToast, toast, navigate, enterPin]);

  return (
    <PageBase h="100%">
      <TopNav title={'Device Setup'} backURL={powerCycleInstructions} />

      {isConnecting ? (
        <VStack>
          <Box px={8} mt={4}>
            <Text fontWeight={'bold'}>Please Wait</Text>
            <Text>We are connecting to your Schneider Charge, this may take up to a few minutes.</Text>
          </Box>
          <CircularLoader color="#57BB59" maxW={162} mt={16} trackWidth={16} />
        </VStack>
      ) : (
        <Flex direction={'column'} align="center" justify={'space-between'} h="100%" maxH={800}>
          <Text data-testid={'ev-setup-text'} px={8} mt={4}>
            Scan the QR code next to your Schneider Charge.
          </Text>
          <Image src={schneiderChargeWifiImg} alt="Schneider Charge Device Wifi Scan" my={4} w="100%" maxW={250} />
          <Flex direction={'column'} align="center" justify={'center'} width="100%" mb={BOTTOM_NAV_HEIGHT}>
            <Button
              variant="solid"
              size="md"
              rounded={50}
              colorScheme="dusk100"
              minW={180}
              px={8}
              fontWeight={500}
              py={6}
              mb={6}
              onClick={handleStartQRCodeScan}
              isDisabled={!IS_NATIVE}
            >
              {IS_NATIVE ? 'Open Camera to Scan QR Code' : 'Scanning not available on web'}
            </Button>
            <Button
              mt={2}
              data-testid={'skip-device-setup'}
              colorScheme={'customBlue'}
              variant="link"
              width="100%"
              maxWidth={'300px'}
              onClick={() => navigate(enterPin)}
            >
              I've connected to the wifi of charger manually
            </Button>
          </Flex>
        </Flex>
      )}
    </PageBase>
  );
}
