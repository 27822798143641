import { useEffect } from 'react';
import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

// Configures the color of the status bar on native platforms, so it aligns with the current color mode
export function useStatusBarStyleConfiguration() {
  const { colorMode } = useColorMode();
  const backgroundColor = useColorModeValue('backgroundLightMode.500', 'customGray.500');

  useEffect(() => {
    /* istanbul ignore next -- @preserve */
    if (Capacitor.isNativePlatform()) {
      const statusBarTextStyle = colorMode === 'dark' ? 'Dark' : 'Light';
      const setStatusBarStyle = async () => {
        await StatusBar.setStyle({ style: Style[statusBarTextStyle] });
        if (Capacitor.getPlatform() === 'android') {
          StatusBar.setBackgroundColor({ color: backgroundColor });
        }
      };
      setStatusBarStyle();
    }
  }, [colorMode, backgroundColor]);
}
