import { Box, FormControl, FormErrorMessage, FormLabel, Input, InputRightAddon } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FlatTariffFormData } from './types';
import { InputGroup } from '@chakra-ui/input';

type Props = {
  seasonIndex: number;
};
export default function ConfigureFlatRate({ seasonIndex }: Props) {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<FlatTariffFormData>();
  const errorsForSeason = errors?.seasons?.[seasonIndex];
  const ratesAreInclusiveOfGST = watch(`ratesAreInclusiveOfGST`);

  return (
    <Box>
      <FormControl w={['100%', '100%', '75%', '50%']} mt={2} isInvalid={!!errorsForSeason?.rate}>
        <FormLabel mb={0} mr={0} fontWeight="bold">
          Flat Rate
        </FormLabel>
        <InputGroup>
          <Input
            data-testid={`season-${seasonIndex}-rate-input`}
            {...register(`seasons.${seasonIndex}.rate`)}
            type={'number'}
            onWheel={(e) => e.currentTarget.blur()}
            min={0}
            step="0.0000000001"
          />
          <InputRightAddon>$ / kWh {ratesAreInclusiveOfGST ? '(incl. GST)' : '(excl. GST)'}</InputRightAddon>
        </InputGroup>
        <FormErrorMessage data-testid={`season-${seasonIndex}-rate-error-message`}>
          {errorsForSeason?.rate?.message}
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
}
