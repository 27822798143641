import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Center, ChakraProvider, ColorModeScript, Heading } from '@chakra-ui/react';
import { store } from './app/store';
import theme from './styles/theme';
import PullToRefresh from './common/components/pull-to-refresh/PullToRefresh';
import ServiceWorkerProvider from 'clipsal-cortex-utils/src/providers/service-worker-provider';
import { VersionCheck } from './app/VersionCheck';
import App from './App';

export const AppProvider = () => {
  return (
    <React.StrictMode>
      <Sentry.ErrorBoundary
        fallback={
          <Center h={'100vh'} w={'100%'}>
            <Heading textAlign={'center'}>
              An error occurred. Please refresh the page to continue. If this error persists, try closing and re-opening
              the app.
            </Heading>
          </Center>
        }
      >
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            <ServiceWorkerProvider>
              <ColorModeScript />
              <PullToRefresh>
                <VersionCheck>
                  <App />
                </VersionCheck>
              </PullToRefresh>
            </ServiceWorkerProvider>
          </ChakraProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
};
