import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type UnmeasuredAppliancesState = {
  unmeasuredAppliances: Record<number, { power: number }>;
};

type UnmeasuredApplianceReducers = {
  // used to inject power data from unmeasured appliances
  updateUnmeasuredAppliancesData: (
    state: UnmeasuredAppliancesState,
    action: PayloadAction<{ siteDeviceId: number; power: number }>
  ) => void;
};

const initialState = {
  // stores extra power data that comes from other device-specific APIs that don't have associated load CTs
  unmeasuredAppliances: {},
};

export const unmeasuredAppliancesSlice = createSlice<UnmeasuredAppliancesState, UnmeasuredApplianceReducers>({
  name: 'unmeasuredAppliances',
  initialState,
  reducers: {
    updateUnmeasuredAppliancesData: (state, { payload }) => ({
      ...state,
      unmeasuredAppliances: { ...state.unmeasuredAppliances, [payload.siteDeviceId]: { power: payload.power } },
    }),
  },
});

export const { updateUnmeasuredAppliancesData } = unmeasuredAppliancesSlice.actions;

export const selectUnmeasuredAppliances = (state: RootState) => state.unmeasuredAppliances.unmeasuredAppliances;

export default unmeasuredAppliancesSlice.reducer;
