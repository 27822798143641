import React, { useEffect, useState, useCallback, useRef } from 'react';
import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react';
import { flushSync } from 'react-dom';
import { Box, Center, Flex, Text } from '@chakra-ui/react';

// Pretty much all of this code was copied and appropriated from the embla carousel website demo. It just works (TM)
const CIRCLE_DEGREES = 360;
const WHEEL_ITEM_SIZE = 30;
const WHEEL_ITEM_COUNT = 18;
const WHEEL_ITEMS_IN_VIEW = 4;

const WHEEL_ITEM_RADIUS = CIRCLE_DEGREES / WHEEL_ITEM_COUNT;
const IN_VIEW_DEGREES = WHEEL_ITEM_RADIUS * WHEEL_ITEMS_IN_VIEW;
const WHEEL_RADIUS = Math.round(WHEEL_ITEM_SIZE / 2 / Math.tan(Math.PI / WHEEL_ITEM_COUNT));

const checkIsInView = (wheelLocation: number, slidePosition: number): boolean =>
  Math.abs(wheelLocation - slidePosition) < IN_VIEW_DEGREES;

type SlideStylesType = {
  opacity: number;
  transform: string;
  label: string | number;
};

const getSlideStyles = (
  emblaApi: EmblaCarouselType,
  index: number,
  loop: boolean,
  items: (string | number)[],
  totalRadius: number
): SlideStylesType => {
  const wheelLocation = emblaApi.scrollProgress() * totalRadius;
  const positionDefault = emblaApi.scrollSnapList()[index] * totalRadius;
  const positionLoopStart = positionDefault + totalRadius;
  const positionLoopEnd = positionDefault - totalRadius;

  let inView = checkIsInView(wheelLocation, positionDefault);
  let angle = index * -WHEEL_ITEM_RADIUS;

  if (checkIsInView(wheelLocation, positionDefault)) {
    inView = true;
  }

  if (loop && checkIsInView(wheelLocation, positionLoopEnd)) {
    inView = true;
    angle = -CIRCLE_DEGREES + (items.length - index) * WHEEL_ITEM_RADIUS;
  }

  if (loop && checkIsInView(wheelLocation, positionLoopStart)) {
    inView = true;
    angle = -(totalRadius % CIRCLE_DEGREES) - index * WHEEL_ITEM_RADIUS;
  }

  if (inView) {
    return {
      label: items[index],
      opacity: 1,
      transform: `rotateX(${angle}deg) translateZ(${WHEEL_RADIUS}px)`,
    };
  }

  return { label: items[index], opacity: 0, transform: 'none' };
};

const getContainerStyles = (wheelRotation: number): Pick<SlideStylesType, 'transform'> => ({
  transform: `translateZ(${WHEEL_RADIUS}px) rotateX(${wheelRotation}deg)`,
});

const getSlidesStyles = (
  emblaApi: EmblaCarouselType | undefined,
  loop: boolean,
  items: (number | string)[],
  totalRadius: number
): SlideStylesType[] => {
  const slidesStyles: SlideStylesType[] = [];

  items.forEach((item, index) => {
    const slideStyle = emblaApi ? getSlideStyles(emblaApi, index, loop, items, totalRadius) : ({} as SlideStylesType);
    slidesStyles.push(slideStyle);
  });

  return slidesStyles;
};

type Props = {
  loop?: boolean;
  items: (number | string)[];
  onSelectItem: (item: number | string) => void;
  defaultValue: string | number;
  ['data-testid']?: string;
  width?: string | number;
  fontSize?: string;
  label?: string;
};

// Note that this component is not "controlled", because of the way it animates using the embla carousel library.
// Instead, pass down a default value and use the onSelectItem callback to get the selected item.
export const VerticalSliderSelect = (props: Props) => {
  const { items, loop = false, onSelectItem, defaultValue, width = '50px', fontSize = '19px', label } = props;
  const slideCount = items.length;
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop,
    axis: 'y',
    dragFree: true,
    containScroll: false,
    watchResize: false,
    watchSlides: false,
    inViewThreshold: 0.4,
  });
  const [wheelReady, setWheelReady] = useState(false);
  const [wheelRotation, setWheelRotation] = useState(0);
  const rootNodeRef = useRef<HTMLDivElement>(null);
  const rootNodeSize = useRef(0);
  const totalRadius = slideCount * WHEEL_ITEM_RADIUS;
  const rotationOffset = loop ? 0 : WHEEL_ITEM_RADIUS;
  const containerStyles = getContainerStyles(wheelRotation);
  const slideStyles = getSlidesStyles(emblaApi, loop, items, totalRadius);

  const inactivateEmblaTransform = useCallback((emblaApi: EmblaCarouselType) => {
    if (!emblaApi) return;
    const { translate, slideLooper } = emblaApi.internalEngine();
    translate.clear();
    translate.toggleActive(false);
    slideLooper.loopPoints.forEach(({ translate }) => {
      translate.clear();
      translate.toggleActive(false);
    });
  }, []);

  const readRootNodeSize = useCallback((emblaApi: EmblaCarouselType) => {
    if (!emblaApi) return 0;
    return emblaApi.rootNode().getBoundingClientRect().height;
  }, []);

  const rotateWheel = useCallback(
    (emblaApi: EmblaCarouselType) => {
      if (!emblaApi) return;
      const rotation = slideCount * WHEEL_ITEM_RADIUS - rotationOffset;
      setWheelRotation(rotation * emblaApi.scrollProgress());
    },
    [slideCount, rotationOffset, setWheelRotation]
  );

  useEffect(() => {
    if (!emblaApi) return;

    emblaApi.on('pointerUp', () => {
      const { scrollTo, target, location } = emblaApi.internalEngine();
      const diffToTarget = target.get() - location.get();
      const factor = Math.abs(diffToTarget) < WHEEL_ITEM_SIZE / 2.5 ? 10 : 0.1;
      const distance = diffToTarget * factor;
      scrollTo.distance(distance, true);
    });

    emblaApi.on('scroll', (carousel) => {
      flushSync(() => rotateWheel(emblaApi));
      onSelectItem(items[carousel.slidesInView()[0]]);
    });

    setWheelReady(true);
    inactivateEmblaTransform(emblaApi);
    rotateWheel(emblaApi);

    if (defaultValue) {
      const index = items.findIndex((item) => item === defaultValue || Number(item) === Number(defaultValue));
      if (index !== -1) {
        emblaApi.scrollTo(index);
      } else {
        console.error('Invalid default value for VerticalSliderSelect: ' + defaultValue);
      }
    }
  }, [emblaApi, inactivateEmblaTransform, rotateWheel]);

  useEffect(() => {
    if (!emblaApi) return;
    if (!rootNodeSize.current) rootNodeSize.current = readRootNodeSize(emblaApi);

    const resizeObserver = new ResizeObserver(() => {
      if (readRootNodeSize(emblaApi) !== rootNodeSize.current) {
        rootNodeSize.current = readRootNodeSize(emblaApi);
        flushSync(() => setWheelReady(false));

        setWheelReady(() => {
          emblaApi.reInit();
          inactivateEmblaTransform(emblaApi);
          rotateWheel(emblaApi);
          return true;
        });
      }
    });

    resizeObserver.observe(emblaApi.rootNode());

    return () => {
      resizeObserver.disconnect();
    };
  }, [emblaApi, inactivateEmblaTransform, setWheelReady, rotateWheel, readRootNodeSize]);

  return (
    <Flex
      data-testid={props?.['data-testid'] ?? 'vertical-slider-select'}
      h={'100%'}
      alignItems="center"
      justifyContent={'center'}
      lineHeight={1}
      fontSize={'1.8rem'}
    >
      <Box overflow={'hidden'} height="100%" w={width} ref={rootNodeRef}>
        <Flex
          h="100%"
          w={'100%'}
          pos="relative"
          style={{
            userSelect: 'none',
          }}
          alignItems={'center'}
          ref={emblaRef}
        >
          <Box
            h={'32px'}
            w={'100%'}
            pos={'absolute'}
            style={
              wheelReady
                ? {
                    transformStyle: 'preserve-3d',
                    ...containerStyles,
                  }
                : { transform: 'none' }
            }
            willChange={'transform'}
          >
            {slideStyles.map((slideStyle, index) => (
              <Center
                opacity={0}
                pos={'absolute'}
                top={0}
                left={0}
                width={'100%'}
                height={'100%'}
                fontSize={fontSize}
                key={index}
                style={
                  wheelReady
                    ? { backfaceVisibility: 'hidden', ...slideStyle }
                    : { position: 'static', transform: 'none' }
                }
              >
                {slideStyle.label}
              </Center>
            ))}
          </Box>
          <Text fontSize={14} marginLeft="auto">
            {label}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};

export default VerticalSliderSelect;
