import { ENV_TYPE } from '../../env-type';
import { API, Auth } from 'aws-amplify';
import type { BaseQueryFn, FetchArgs } from '@reduxjs/toolkit/query/react';
import { HTTPMethod } from '../../features/demo-login/route-map';
import { IS_DEMO_LOGIN } from '../constants';
import { AxiosError } from 'axios';

/**
 * Performs a GET request to a provided name/resource path on the API.
 *
 * @param path The path from the end point resource
 */
export async function get<T = any>(path: string): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest('GET', path);
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return await API.get('site', path, {
    headers,
  });
}

/**
 * Performs a POST request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function post<T = any>(path: string, body: any): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest('POST', path, body);
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env?.[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.post('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a POST request to a provided public name/resource path on the API that doesn't require auth.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function postPublic<T = any>(path: string, body: object): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest('POST', path, body);
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'x-api-key': import.meta.env?.[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };
  return await API.post('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a PATCH request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function patch<T = any>(path: string, body: object): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest('PATCH', path, body);
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env?.[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.patch('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a DELETE request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function del<T = any>(path: string, body: object): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest('DELETE', path);
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env?.[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.del('site', path, {
    headers,
    body,
  });
}

/**
 * Performs a PUT request to a provided name/resource path on the API.
 *
 * @param path The path of the resource.
 * @param body The request body.
 */
export async function put<T = any>(path: string, body: any): Promise<T> {
  if (IS_DEMO_LOGIN) return handleStubbedAPIRequest('PUT', path, body);
  // Auth.currentSession() automatically uses local session variables to self-refresh, preventing unnecessary API calls.
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'x-api-key': import.meta.env?.[`VITE_${ENV_TYPE}_API_KEY`] as string,
  };

  return await API.put('site', path, {
    headers,
    body,
  });
}

async function handleStubbedAPIRequest<T>(method: HTTPMethod, path: string, body?: any): Promise<T> {
  const { default: handleDemoLoginAPIRequest } = await import('../../features/demo-login/route-map');
  const fakeResponse = handleDemoLoginAPIRequest(method, path, body);

  return new Promise((res) => {
    setTimeout(() => {
      res(fakeResponse);
    }, 200);
  });
}

export type ApiFunction = ((path: string, body: object) => Promise<any>) | ((path: string) => Promise<any>);
export type APIMethods = 'GET' | 'POST' | 'POST_PUBLIC' | 'DELETE' | 'PATCH' | 'PUT';
export type QueryArgs = string | (FetchArgs & { method: APIMethods });

const mapApiMethodToApiFunction: Record<APIMethods, ApiFunction> = {
  GET: get,
  POST: post,
  POST_PUBLIC: postPublic,
  DELETE: del,
  PATCH: patch,
  PUT: put,
};

export type ErrorResponse = {
  detail: string;
  code: string;
  status_code: number;
};

export type RTKQError = { status: number; message: string; originalError: AxiosError };

// TODO try to fix this type incompatibility.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const customBaseQuery: BaseQueryFn<QueryArgs, unknown, RTKQError> = async (args) => {
  try {
    const url = typeof args === 'string' ? args : args.url;
    const method = typeof args === 'string' ? 'GET' : args.method;
    const body = typeof args === 'string' ? undefined : args.body;
    const apiFunction = mapApiMethodToApiFunction[method || 'GET'];
    const data = await apiFunction(url, body);
    return { data };
  } catch (e: any) {
    const error = e as AxiosError<ErrorResponse>;
    return {
      error: {
        status: error?.status ?? error?.response?.status ?? 500,
        message: error?.message || error?.response?.data?.detail || '',
        originalError: error,
      },
    };
  }
};
