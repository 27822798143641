import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import LogRocket from 'logrocket';
import ReactGA from 'react-ga4';
import * as amplitude from '@amplitude/analytics-browser';
import useAppVisibility from 'clipsal-cortex-utils/src/hooks/use-app-visibility';
import { IS_NOT_DEVELOPMENT, IS_RUNNING_CYPRESS_TESTS } from '../constants';

const lastViewedDate = new Date();
// Updates each time the app is minimized then maximized, then set back to 0 on route change.
let timeSpendIdleMS = 0;

// Handles tracking page views in Amplitude and Google Analytics
/* istanbul ignore next -- @preserve */
export const useAmplitude = () => {
  const { pathname } = useLocation();
  // Used to track time spent on each page in amplitude, subtracting time the app is spent minimized
  const appMinimizedTimestamp = useRef<Date | null>(null);

  useEffect(() => {
    // Initial path on load
    let previouslyVisitedPath = pathname.replace(/^\/site\/[0-9]+\//, '/site/<id>/');

    if (IS_NOT_DEVELOPMENT && !IS_RUNNING_CYPRESS_TESTS) {
      // replace the site ID with a placeholder
      const pathnameAmplitude = pathname.replace(/^\/site\/\d+\//, '/site/<id>/');

      const now = new Date();
      const differenceInMS = now.getTime() - lastViewedDate.getTime() - timeSpendIdleMS;
      lastViewedDate.setTime(now.getTime());

      timeSpendIdleMS = 0;

      // Required event properties for page views
      const eventProperties: Record<string, string> = {
        '[Amplitude] Page Path': pathnameAmplitude,
      };

      // If we have more properties to add, add them here
      if (window.location) {
        const { hostname, href, origin, host, pathname } = window.location;
        eventProperties['[Amplitude] Page Domain'] = hostname;
        eventProperties['[Amplitude] Page Location'] = href;
        eventProperties['[Amplitude] Page URL'] = href;
        eventProperties['Previous Path'] = previouslyVisitedPath;
        eventProperties['Seconds Spent on Previous Path'] = (differenceInMS / 1000).toString();
        eventProperties['LogRocket Session URL'] = LogRocket.sessionURL ?? 'NO_SESSION_AVAILABLE';
        eventProperties['referrer'] = origin + pathname;
        eventProperties['referring_domain'] = host;
      }

      ReactGA.send({ hitType: 'pageview', page: pathnameAmplitude });
      amplitude.logEvent('[Amplitude] Page Viewed', eventProperties);

      previouslyVisitedPath = pathnameAmplitude;
    }
  }, [pathname]);

  const handleAppVisibilityChange = useCallback((isVisible: boolean) => {
    if (isVisible && appMinimizedTimestamp.current) {
      const now = new Date();
      const timeSinceMinimizedMS = now.getTime() - appMinimizedTimestamp.current.getTime();
      timeSpendIdleMS += timeSinceMinimizedMS;
      appMinimizedTimestamp.current = now;
    } else {
      // App was minimized
      appMinimizedTimestamp.current = new Date();
    }
  }, []);

  useAppVisibility(handleAppVisibilityChange);
};
