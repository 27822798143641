import { Badge, Box, BoxProps, Flex, Grid, Image, Skeleton, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useGetRetailerImageURLQuery } from '../billsApi';
import { SiteTariff, TariffType } from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import { formatNiceDate, formatNiceDateFromString } from 'clipsal-cortex-utils/src/formatting/formatting';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { ITEMS_PER_PAGE, LIST_ITEM_HEIGHT_PX } from './constants';
import { useSelector } from 'react-redux';
import { selectSite } from '../../site/siteSlice';
import { useTariffs } from './tariffApi';

type Props = {
  tariff: SiteTariff;
  offset: number;
  containerProps?: BoxProps & { 'data-testid': string };
  // The tariff which is applicable after this tariff. Set to `null` when there is no successive tariff (it applies
  // until the present)
  successiveTariff: SiteTariff | null;
};

const TARIFF_TYPE_TO_COLOR: Record<TariffType, string> = {
  FLAT: 'red',
  TIERED: 'purple',
  TOU: 'green',
  REAL_TIME: 'blue',
};

export default function TariffListItem({ tariff, containerProps, successiveTariff, offset }: Props) {
  const navigate = useNavigate();
  const { site_id: siteId } = useSelector(selectSite);
  const { data, isLoading } = useGetRetailerImageURLQuery(tariff.tariff?.retailer?.id ?? 0);
  const imageBase64 = data?.base64_file ?? '';
  // If we're on the page which isn't the first one, we need to query data from the previous page to discern the
  // end date of the first tariff.
  const visiblePageNum = offset / ITEMS_PER_PAGE;
  const {
    tariffs: previousPageTariffs,
    isLoading: isPreviousPageTariffsLoading,
    isError: isPreviousPageTariffsError,
  } = useTariffs(
    {
      offset: offset - ITEMS_PER_PAGE,
      limit: ITEMS_PER_PAGE,
    },
    visiblePageNum === 0
  );
  const successiveTariffDateFormatted = useMemo(() => {
    if (!successiveTariff) {
      if (visiblePageNum === 0) {
        return 'Present';
      } else {
        if (isPreviousPageTariffsLoading) return 'Loading...';
        if (isPreviousPageTariffsError) return 'N/A';
        const discoveredSuccessiveTariff = previousPageTariffs[previousPageTariffs.length - 1];
        // Make the successive date inclusive
        const successiveTariffDate = new Date(discoveredSuccessiveTariff.tariff_effective_date.replace(/-/g, '/'));
        successiveTariffDate.setDate(successiveTariffDate.getDate() - 1);
        return formatNiceDate(successiveTariffDate);
      }
    }

    // Make the successive date inclusive
    const successiveTariffDate = new Date(successiveTariff.tariff_effective_date.replace(/-/g, '/'));
    successiveTariffDate.setDate(successiveTariffDate.getDate() - 1);
    return formatNiceDate(successiveTariffDate);
  }, [successiveTariff, visiblePageNum, isPreviousPageTariffsLoading, isPreviousPageTariffsError, previousPageTariffs]);

  return (
    <Box
      width={'100%'}
      as={'button'}
      onClick={() => navigate(`/site/${siteId}/bills/tariffs/${tariff.id}/review?backURL=/site/${siteId}/bills`)}
      _hover={{ background: 'rgba(0, 0, 0, 0.04)' }}
      height={`${LIST_ITEM_HEIGHT_PX}px`}
      borderBottom="1px solid"
      borderColor="darkModeBorderColor.500"
      _dark={{
        borderColor: 'textGrey.500',
      }}
      {...containerProps}
    >
      <Grid templateColumns={'50px 1fr'}>
        <Skeleton rounded={50} isLoaded={!isLoading}>
          <Image src={`data:imageBase64/png;base64,${imageBase64}`} alt={!isLoading ? 'Retailer logo' : ''} />
        </Skeleton>
        <Flex justify="space-between" w={'100%'}>
          <Flex justify="space-between" w="100%" align="center">
            <Flex ml={2} justify={'center'} align={'flex-start'} direction={'column'}>
              <Flex align="center">
                <Text textAlign="start" fontWeight={'bold'}>
                  {tariff.tariff.plan_name}
                </Text>
              </Flex>
              <Text>
                {formatNiceDateFromString(tariff.tariff_effective_date)}
                {' - '}
                {successiveTariffDateFormatted}
              </Text>
            </Flex>
            <Box>
              <Badge ml={1} colorScheme={TARIFF_TYPE_TO_COLOR[tariff.tariff.tariff_type]}>
                {tariff.tariff.tariff_type.replace('_', '-')}
              </Badge>
            </Box>
          </Flex>
          <ChevronRightIcon w={6} h={6} color={'rgba(0, 0, 0, 0.25)'} alignSelf={'center'} />
        </Flex>
      </Grid>
    </Box>
  );
}
