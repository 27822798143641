import { PaginatedResponseV2 } from 'clipsal-cortex-types/src/common/types';
import { Retailer } from 'clipsal-cortex-types/src/api/api-tariffs';
import { SiteTariff, SiteTariffToSave } from 'clipsal-cortex-types/src/api/api-tariffs-v2';
import { getStringifiedQueryParams } from 'clipsal-cortex-utils/src/formatting/query-params';
import { useSelector } from 'react-redux';
import { baseApi } from '../../../app/services/baseApi';
import { selectSite } from '../../site/siteSlice';
import { TariffFormDataState } from './tariff-form-context';
import { mapAPIValuesToForm } from './form-mapping-utils';
import { TariffTemplate } from 'clipsal-cortex-types/src/api/api-tariff-templates';
import * as Sentry from '@sentry/react';

export type RetailerQueryParams = {
  limit: number;
  offset: number;
  country_code?: string;
  search?: string;
};

export type TariffQueryParams = {
  limit: number;
  offset: number;
};

export const tariffApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getRetailers: build.query<PaginatedResponseV2<Retailer>, RetailerQueryParams | undefined>({
      query: (params) => {
        let uri = `/v1/retailers?country_code=AU`;
        if (params) uri += `&${getStringifiedQueryParams<string>(params)}`;
        return uri;
      },
      providesTags: ['Retailers'],
    }),
    getTariffs: build.query<
      PaginatedResponseV2<Required<SiteTariff>>,
      { siteId: number; params: Partial<TariffQueryParams> }
    >({
      query: ({ siteId, params }) => {
        const stringifiedParams = getStringifiedQueryParams<string>(params);
        return `/v2/sites/${siteId}/tariffs${stringifiedParams ? '?' : ''}${stringifiedParams}`;
      },
      providesTags: ['Tariffs'], // @TODO: separate cache by page
    }),
    getTariffTemplates: build.query<TariffTemplate[], { distributor_id: number; retailer_id: number }>({
      query: (params) => {
        const stringifiedParams = getStringifiedQueryParams<string>(params);
        return `/v1/tariff_templates${stringifiedParams ? '?' : ''}${stringifiedParams}`;
      },
      providesTags: ['TariffTemplates'],
    }),
    getTariffForForm: build.query<TariffFormDataState, number>({
      query: (siteTariffId) => `/v2/site_tariffs/${siteTariffId}`,
      providesTags: ['Tariff'],
      transformResponse: (apiTariff: Required<SiteTariff>) => {
        try {
          return mapAPIValuesToForm(apiTariff);
        } catch (e) {
          Sentry.captureException(e, {
            extra: {
              // NOTE: this must be a string, nested structures don't show in Sentry
              tariffAPIData: JSON.stringify(apiTariff),
            },
          });

          throw e;
        }
      },
    }),
    createTariff: build.mutation<SiteTariff, { siteId: number; body: SiteTariffToSave }>({
      query: ({ siteId, body }) => ({
        url: `/v2/sites/${siteId}/tariffs`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tariffs', 'Tariff'],
    }),
    updateTariff: build.mutation<SiteTariff, SiteTariffToSave>({
      query: (body) => ({
        url: `/v2/site_tariffs/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Tariffs', 'Tariff'],
    }),
    deleteTariff: build.mutation<SiteTariff, number>({
      query: (tariffId) => ({
        url: `/v2/site_tariffs/${tariffId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['Tariffs'],
    }),
  }),
});

export const {
  useGetRetailersQuery,
  useGetTariffsQuery,
  useGetTariffTemplatesQuery,
  useGetTariffForFormQuery,
  useUpdateTariffMutation,
  useCreateTariffMutation,
  useDeleteTariffMutation,
} = tariffApi;

export const useRetailers = (params?: RetailerQueryParams) => {
  const queryResult = useGetRetailersQuery(params);
  const { data } = queryResult;

  return {
    ...queryResult,
    retailers: data?.results ?? [],
    count: data?.count ?? 0,
  };
};

export const useTariffs = (params?: Partial<TariffQueryParams>, skip = false) => {
  const site = useSelector(selectSite);
  const queryResult = useGetTariffsQuery({ params: params ?? {}, siteId: site.site_id }, { skip });
  const { data } = queryResult;

  return {
    ...queryResult,
    tariffs: data?.results ?? [],
    count: data?.count ?? 0,
  };
};

export const useMostRecentTariff = () => {
  const queryResult = useTariffs({ offset: 0, limit: 5 });
  const { data: tariffs } = queryResult;
  const mostRecentTariff = tariffs?.results?.[0];

  return {
    ...queryResult,
    data: mostRecentTariff,
  };
};
