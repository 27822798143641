import { useBreakpointValue } from '@chakra-ui/react';
import { getViewportType, ViewportType } from '../calculations/viewport-helpers';

const VIEWPORT_TYPE_TO_FONT_SIZES = {
  mobile: { selected: '12px', normal: '10px' },
  tablet: { selected: '12px', normal: '10px' },
  desktop: { selected: '14px', normal: '12px' },
  'desktop-lg': { selected: '16px', normal: '14px' },
};

interface useViewportType {
  viewportType: ViewportType;
  fontSizes: {
    selected: string;
    normal: string;
  };
  isDesktopViewport: boolean;
}

export const useViewportType = (): useViewportType => {
  const isRunningCypressTest = (window as any).Cypress;
  const viewportType: ViewportType =
    useBreakpointValue(
      {
        base: 'mobile',
        sm: 'tablet',
        md: 'desktop',
        lg: 'desktop-lg',
        xl: 'desktop-lg',
      },
      { ssr: isRunningCypressTest ? true : false }
    ) || getViewportType(window.innerWidth);

  const fontSizes = VIEWPORT_TYPE_TO_FONT_SIZES[viewportType];

  const isDesktopViewport = ['desktop-lg', 'desktop'].includes(viewportType);

  return { viewportType, fontSizes, isDesktopViewport };
};
