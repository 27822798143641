import React from 'react';
import { Box, CloseButton, useColorModeValue } from '@chakra-ui/react';

interface Props {
  children?: any;
  shadow?: string;
  isDismissable?: boolean;
  isDismissed?: boolean;
  onDismiss?: (isDismissed: boolean) => void;
  cardBackground?: string;
  padding?: number;
  rounded?: number;
  [styleType: string]: any;
}

const Card = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  // Extract props which are not specific to this component, we can spread them after applying specific ones directly.
  const propsWithoutCardSpecificProps = Object.fromEntries(
    Object.entries(props).filter(([key]) => {
      return ![
        'shadow',
        'isDismissable',
        'isDismissed',
        'cardBackground',
        'padding',
        'isDesktopViewport',
        'onDismiss',
      ].includes(key);
    })
  );
  const backgroundColor = useColorModeValue('white', 'gray.900');

  return !props.isDismissed ? (
    <Box
      ref={ref}
      p={props.padding || props.p || 5}
      bg={props.cardBackground !== undefined ? props.cardBackground : backgroundColor}
      rounded={props.rounded !== undefined ? props.rounded : 20}
      shadow={props.shadow !== undefined ? props.shadow : 'md'}
      mb={props.marginBottom || props.mb || 2}
      {...propsWithoutCardSpecificProps}
    >
      {props.isDismissable && (
        <CloseButton
          data-testid="card-close-btn"
          onClick={() => props.onDismiss && props.onDismiss(true)}
          mt={-3}
          mr={-3}
          float="right"
        />
      )}
      {props.children}
    </Box>
  ) : (
    <></>
  );
});

export default Card;
