import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface Dashboard {
  isExperimentalFeaturesModalOpen: boolean;
}

export type DashboardReducers = {
  toggleExperimentalFeaturesModal: (state: Dashboard, action: PayloadAction<boolean>) => void;
};

const initialState = {
  articles: [],
  image: '',
  isExperimentalFeaturesModalOpen: false,
  isEligibleForSmartDevice: { isEligible: false, isLoaded: false },
  statsToDate: {
    hasFetched: false,
    errorType: null,
    data: {
      boughtFromGrid: 0,
      soldToGrid: 0,
      saved: 0,
      startDate: new Date(),
    },
  },
};

export const dashboardSlice = createSlice<Dashboard, DashboardReducers>({
  name: 'dashboard',
  initialState,
  reducers: {
    toggleExperimentalFeaturesModal: (state, action) => {
      return {
        ...state,
        isExperimentalFeaturesModalOpen: action.payload,
      };
    },
  },
});

export const { toggleExperimentalFeaturesModal } = dashboardSlice.actions;

export const selectIsExperimentalFeaturesModalOpen = (state: RootState) => {
  return state.dashboard.isExperimentalFeaturesModalOpen;
};

export default dashboardSlice.reducer;
