import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TieredTariffFormData } from './types';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  Input,
  InputRightAddon,
  FormLabel,
  Heading,
  Divider,
  IconButton,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { InputGroup } from '@chakra-ui/input';
import { EMPTY_TIER_TEMPLATE } from './constants';
import CustomAddButton from '../common/CustomAddButton';
import { DeleteIcon } from '@chakra-ui/icons';
import ChargePeriodSelect from './ChargePeriodSelect';
import InfoPopover from 'clipsal-cortex-ui/src/components/InfoPopover';

type Props = {
  seasonIndex: number;
};

export default function ConfigureTieredRate({ seasonIndex }: Props) {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext<TieredTariffFormData>();
  const {
    fields: rateTiers,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `seasons.${seasonIndex}.tiers`,
  });
  const ratesAreInclusiveOfGST = watch(`ratesAreInclusiveOfGST`);
  const errorsForSeason = errors?.seasons?.[seasonIndex];

  return (
    <Box>
      <FormControl w={['100%', '100%', '50%', '30%']} mb={3} isInvalid={!!errorsForSeason?.chargePeriod}>
        <FormLabel>
          Charge Period
          <InfoPopover>
            This describes the billing cycle over which energy usage is measured and charged, before being reset. If
            this information is not listed on your bill, please contact your energy retailer.
          </InfoPopover>
        </FormLabel>
        <ChargePeriodSelect seasonIndex={seasonIndex} />
        <FormErrorMessage data-testid={`season-${seasonIndex}-charge-period-error-message`}>
          {errorsForSeason?.chargePeriod?.message}
        </FormErrorMessage>
      </FormControl>

      <Divider my={5} />

      {rateTiers.map((rateTier, rateTierIndex) => {
        const errorsForRateTier = errorsForSeason?.tiers?.[rateTierIndex];

        return (
          <Box mt={rateTierIndex === 0 ? 0 : 4} key={rateTier.id}>
            {rateTierIndex !== 0 && <Divider my={4} />}
            <Flex align="center">
              <Heading size="sm">TIER {rateTierIndex + 1}</Heading>

              {rateTiers.length > 2 && (
                <IconButton
                  ml={[0, 0, 2]}
                  size={'sm'}
                  onClick={() => remove(rateTierIndex)}
                  color="customRed.500"
                  variant="ghost"
                  icon={<DeleteIcon />}
                  aria-label={`Delete tier ${rateTierIndex + 1} for season ${seasonIndex}`}
                  data-testid={`season-${seasonIndex}-tier-${rateTierIndex}-delete-tier-btn`}
                />
              )}
            </Flex>

            {/* The last tier never has an upper limit */}
            {rateTierIndex !== rateTiers.length - 1 ? (
              <Flex justify={[`space-between`, `space-between`, 'flex-start']} align="center" mt={2}>
                <Text mr={[0, 0, 3]}>{rateTierIndex === 0 ? 'First' : 'Next'}</Text>
                <FormControl ml={2} w={['60%', '60%', '40%', '25%']} isInvalid={!!errorsForRateTier?.upperLimitKWh}>
                  <InputGroup>
                    <Input
                      data-testid={`season-${seasonIndex}-rate-tier-${rateTierIndex}-upper-limit-input`}
                      {...register(`seasons.${seasonIndex}.tiers.${rateTierIndex}.upperLimitKWh`)}
                      type={'number'}
                      onWheel={(e) => e.currentTarget.blur()}
                      min={0}
                      step="0.0000000001"
                    />
                    <InputRightAddon>kWh</InputRightAddon>
                  </InputGroup>
                  <FormErrorMessage
                    data-testid={`season-${seasonIndex}-rate-tier-${rateTierIndex}-upper-limit-error-message`}
                  >
                    {errorsForRateTier?.upperLimitKWh?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            ) : (
              <Alert mt={2} w={['100%', '100%', '40%']} variant="left-accent" status="info">
                <AlertIcon />
                The highest rate tier has no usage limit.
              </Alert>
            )}

            <Flex mt={2}>
              <FormControl w={['100%', '100%', '50%', '30%']} isInvalid={!!errorsForRateTier?.rate}>
                <FormLabel>Rate</FormLabel>
                <InputGroup>
                  <Input
                    data-testid={`season-${seasonIndex}-rate-tier-${rateTierIndex}-rate-input`}
                    {...register(`seasons.${seasonIndex}.tiers.${rateTierIndex}.rate`)}
                    type={'number'}
                    onWheel={(e) => e.currentTarget.blur()}
                    min={0}
                    step="0.0000000001"
                  />
                  <InputRightAddon>$ / kWh {ratesAreInclusiveOfGST ? '(incl. GST)' : '(excl. GST)'}</InputRightAddon>
                </InputGroup>
                <FormErrorMessage data-testid={`season-${seasonIndex}-rate-tier-${rateTierIndex}-rate-error-message`}>
                  {errorsForRateTier?.rate?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
          </Box>
        );
      })}

      <CustomAddButton
        data-testid="add-new-tier"
        mt={3}
        onClick={() => {
          append(EMPTY_TIER_TEMPLATE);
        }}
      >
        Add Tier
      </CustomAddButton>
    </Box>
  );
}
