import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Center, Flex, Grid, Heading, Text, useToast } from '@chakra-ui/react';
import { useViewportType } from '../../../common/hooks/use-viewport-type';
import { SiteSubscriptionOffer } from 'clipsal-cortex-types/src/api/api-subscriptions';
import { useSelector } from 'react-redux';
import { CustomLockIcon, SelectSubscriptionTickIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { get, post } from '../../../common/api/api-helpers';
import { fetchUserDetails, selectUser } from '../../user/userSlice';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';
import { useReduxDispatch } from '../../../app/store';
import { IS_DEMO_LOGIN, IS_NOT_DEVELOPMENT, IS_PRODUCTION } from '../../../common/constants';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

export default function SelectSubscriptionPlan() {
  const { isDesktopViewport } = useViewportType();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoadingSubscriptionOfferLink, setLoadingSubscriptionOfferLink] = useState(false);
  const [isLoaded, offers] = useFetchSubscriptionOffers();
  const [selectedOfferIndex, setSelectedOfferIndex] = useState<number>(0);

  function getBillingFrequency(billingFrequencyInMonths: number) {
    if (billingFrequencyInMonths % 12 === 0) {
      const years = billingFrequencyInMonths / 12;

      if (years === 1) {
        return `per year`;
      } else {
        return `per ${years} years`;
      }
    } else {
      if (billingFrequencyInMonths === 1) {
        return `per month`;
      } else {
        return `per ${billingFrequencyInMonths} months`;
      }
    }
  }

  if (!isLoaded) return <CenteredLoader />;

  // The user should never get here on a native platform, but in case something weird happens, this is here to ensure
  // they can't proceed.
  /* istanbul ignore next -- @preserve */
  if (Capacitor.isNativePlatform()) {
    return (
      <Box mb={3}>
        <Box>Visit our website at https://app.clipsalcortex.com/ to continue.</Box>
        <Text textAlign="center">
          You can view the{' '}
          <Box
            cursor={'pointer'}
            onClick={() => {
              localStorage.setItem('isDemoLogin', 'true');
              localStorage.setItem('isDemoLoginFromSubscriptionPlansPage', 'true');

              // Refresh to update local storage variable
              window.location.replace('/');
            }}
            fontWeight={'bold'}
            color={'customBlue.500'}
            as={'span'}
          >
            demo
          </Box>{' '}
          to check out the Cortex application.
        </Text>
      </Box>
    );
  }

  return (
    <>
      <Heading mb={2} size={'lg'}>
        Subscribe & unlock energy insights!
      </Heading>
      <Grid templateColumns={isDesktopViewport && offers.length > 1 ? 'repeat(2, 1fr)' : '1fr'}>
        {offers.map((offer, offerIndex) => (
          <Center
            data-testid="site-subscription-offer"
            onClick={() => setSelectedOfferIndex(offerIndex)}
            cursor="pointer"
            position={'relative'}
            m={2}
            p={8}
            key={offer.id}
            flexDir={'column'}
            rounded={5}
            border={'1px solid'}
            borderColor={selectedOfferIndex === offerIndex ? 'primaryBranding.500' : 'darkModeBorderColor.500'}
          >
            {selectedOfferIndex === offerIndex && (
              <SelectSubscriptionTickIcon w={8} h={8} position={'absolute'} right={-4} top={-4} />
            )}
            <Flex mb={2} direction="column" align="center">
              <Center mb={2} flexDirection={'column'}>
                <Flex align="center">
                  <Text mr={1}>$</Text>
                  <Heading size="xl" mr={2}>
                    {offer.subscription_offer.billing_amount}
                  </Heading>
                </Flex>
                <Box mt={-2} fontSize="sm">
                  {getBillingFrequency(offer.subscription_offer.billing_frequency_months)}
                </Box>
              </Center>
              <Heading size="md">{offer.subscription_offer.title}</Heading>
            </Flex>
            <Text fontSize={isDesktopViewport ? 'md' : 'sm'}>{offer.subscription_offer.description}</Text>
          </Center>
        ))}
      </Grid>

      <Center>
        <Box>
          <Text mb={2} textAlign="center">
            <CustomLockIcon mr={1} as={'span'} />
            You will now be taken to our secure payment page to choose your plan and payment method
          </Text>
          <Text textAlign="center">
            View the{' '}
            <Box
              cursor={'pointer'}
              onClick={() => {
                localStorage.setItem('isDemoLogin', 'true');
                localStorage.setItem('isDemoLoginFromSubscriptionPlansPage', 'true');

                // Refresh to update local storage variable
                window.location.href = window.location.origin;
              }}
              fontWeight={'bold'}
              color={'customBlue.500'}
              as={'span'}
            >
              demo
            </Box>{' '}
            to check out the Cortex application.
          </Text>

          <Center flexDirection="column">
            <Button
              mx={'auto'}
              data-testid="proceed-with-subscription-offer"
              onClick={async () => {
                setLoadingSubscriptionOfferLink(true);

                // If there is a specific site provided by query parameters, ensure that is used.
                // Otherwise, if the user is a system owner with only one site, just use that one (most new users).
                const params = new URLSearchParams(location.search);
                let siteID = Number(params.get('siteID'));
                if (user.siteIDs.length > 1 && !siteID) {
                  // The user has more than 1 site, and there is no site ID in the query parameters.
                  toast({
                    title: 'Something went wrong',
                    description: 'Please contact support regarding creating or renewing your subscription',
                    status: 'error',
                    isClosable: true,
                  });
                } else {
                  // If a site ID is supplied in query parameters, use that, otherwise grab the user's only site.
                  siteID = siteID || user.siteIDs[0];
                }

                try {
                  const subscriptionLink = await post<{ checkout_link: string }>(`/v1/sites/${siteID}/checkout_link`, {
                    site_subscription_offer_id: offers[selectedOfferIndex].id,
                  });

                  window.location.href = subscriptionLink.checkout_link;
                } catch (e) {
                  toast({
                    title: 'Error fetching subscription checkout link',
                    description: 'Please try again. If this issue persists, please contact support.',
                    status: 'error',
                    isClosable: true,
                  });
                }
              }}
              w={'80%'}
              isLoading={isLoadingSubscriptionOfferLink}
              mt={4}
              rounded={20}
              colorScheme="dusk100"
            >
              Next
            </Button>

            <Button
              data-testid="go-back"
              mx={'auto'}
              onClick={() => {
                navigate('/logout');
              }}
              w={'80%'}
              mt={1}
              variant="outline"
              rounded={20}
              colorScheme="dusk100"
            >
              Logout
            </Button>
          </Center>
        </Box>
      </Center>
    </>
  );
}

function useFetchSubscriptionOffers(): [boolean, SiteSubscriptionOffer[]] {
  const [isLoaded, setLoaded] = useState(false);
  const [offers, setOffers] = useState<SiteSubscriptionOffer[]>([]);
  const user = useSelector(selectUser);
  const toast = useToast();
  const dispatch = useReduxDispatch();

  const fetchSubscriptionPlans = useCallback(async () => {
    // If the user clicked the 'back' button to move out of demo mode, make sure we get out of demo mode.
    if (IS_DEMO_LOGIN) {
      localStorage.removeItem('isDemoLogin');
      window.location.reload();
    }

    let userFromAPI = user;
    if (!userFromAPI.userID) {
      userFromAPI = unwrapResult(
        await dispatch(
          fetchUserDetails({
            isProduction: IS_PRODUCTION,
            isNotDevelopment: IS_NOT_DEVELOPMENT,
          })
        )
      );
    }

    // If there is a specific site provided by query parameters, ensure that is used.
    // Otherwise, if the user is a system owner with only one site, just use that one (most new users).
    const params = new URLSearchParams(location.search);
    let siteID = Number(params.get('siteID'));
    if (userFromAPI.siteIDs.length > 1 && !siteID) {
      toast({
        title: 'Something went wrong',
        description: 'Please contact support regarding creating or renewing your subscription',
        status: 'error',
        isClosable: true,
      });
    } else {
      siteID = siteID || userFromAPI.siteIDs[0];
    }

    try {
      setOffers(await get<SiteSubscriptionOffer[]>(`/v1/sites/${siteID}/subscription_offers`));
      setLoaded(true);
    } catch (e) {
      toast({
        title: 'Error fetching subscription offers',
        description: 'Please try again. If this issue persists, please contact support.',
        status: 'error',
        isClosable: true,
      });
    }
  }, [dispatch, toast, user]);

  useEffect(() => {
    if (!isLoaded) fetchSubscriptionPlans();
  }, [dispatch, fetchSubscriptionPlans, isLoaded, toast, user]);

  return [isLoaded, offers];
}
