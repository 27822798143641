import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { MenuProps, Select, chakraComponents, GroupBase, OptionProps } from 'chakra-react-select';
import { TimeOfUsePeriodSelectOption, TOUTariffFormData } from './types';
import { TOU_PERIOD_SELECT_OPTIONS } from './constants';
import { Box } from '@chakra-ui/react';

type Props = {
  formPath: TOUPeriodSelectFormPath;
  isDisabled?: boolean;
  ['data-testid']?: string;
};

export type TOUPeriodSelectFormPath =
  | `seasons.${number}.shoulderRates.${number}.timesOfUse.${number}.applicablePeriods`
  | `seasons.${number}.peakRate.timesOfUse.${number}.applicablePeriods`
  | `seasons.${number}.offPeakRate.timesOfUse.${number}.applicablePeriods`;

export default function TOUPeriodSelect(props: Props) {
  const { formPath, isDisabled } = props;
  const { control } = useFormContext<TOUTariffFormData>();
  return (
    <Controller
      control={control}
      name={formPath}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        // Options can vary based on the currently selected value.
        let options = [...TOU_PERIOD_SELECT_OPTIONS];

        if (value.find((v) => v.value === 'EVERYDAY')) {
          options = options.filter((o) => !['WEEKDAYS', 'WEEKENDS'].includes(o.value));
        }

        return (
          <Box data-testid={props['data-testid']}>
            <Select
              ref={ref}
              components={{
                // Overrides the ID for the menu and options to facilitate testing
                Menu: (
                  menuProps: MenuProps<TimeOfUsePeriodSelectOption, true, GroupBase<TimeOfUsePeriodSelectOption>>
                ) => <chakraComponents.Menu className={props['data-testid'] + '-menu'} {...menuProps} />,
                Option: (
                  optionProps: OptionProps<TimeOfUsePeriodSelectOption, true, GroupBase<TimeOfUsePeriodSelectOption>>
                ) => (
                  <chakraComponents.Option
                    className={
                      props['data-testid'] + `-option-${optionProps.data.value.toLowerCase().replace('_', '-')}`
                    }
                    {...optionProps}
                  />
                ),
              }}
              isDisabled={isDisabled}
              onChange={(newValue) => {
                // The new value includes the old value too since it's an array, so we have to find the difference to
                // get the actual newly-added array item from the event
                let valueToSave = newValue;
                const newlyAddedItem = newValue.find((v) => !value.find((oldValue) => oldValue === v))!;
                // If the user has already selected weekdays or weekends, and they select weekdays/weekends or every
                // day, set the value to every day
                if (
                  newlyAddedItem &&
                  value.find((v) => ['WEEKDAYS', 'WEEKENDS'].includes(v.value)) &&
                  ['WEEKENDS', 'EVERYDAY', 'WEEKDAYS'].includes(newlyAddedItem.value)
                ) {
                  valueToSave = [
                    ...valueToSave.filter((v) => !['WEEKDAYS', 'WEEKENDS', 'EVERYDAY'].includes(v.value)),
                    { label: 'Everyday', value: 'EVERYDAY' },
                  ];
                }

                onChange(valueToSave);
              }}
              placeholder={'Select your period..'}
              onBlur={onBlur}
              // Not specifying portal target can cause the menu to become hidden.
              menuPortalTarget={document.body}
              options={options}
              value={value}
              useBasicStyles
              isMulti
            />
          </Box>
        );
      }}
    />
  );
}
