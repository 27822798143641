import React from 'react';
import PageBase from '../../../../../common/components/PageBase';
import { COMMON_BASE_PAGE_X_PADDING } from '../../constants';
import TopNav from '../../../../../common/components/TopNav';
import { Alert, AlertIcon, Box, Button, Center, Heading, useDisclosure, useToast } from '@chakra-ui/react';
import SeasonConfigurationDialogButton from '../common/SeasonConfigurationDialogButton';
import SeasonConfigurationFormDialog, { SeasonFormData } from '../common/SeasonConfigurationFormDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSite } from '../../../../site/siteSlice';
import useViewportType from '../../../../../common/hooks/use-viewport-type';
import { useTariffFormContext } from '../../tariff-form-context';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TieredTariffFormData } from './types';
import FormContainer from '../../FormContainer';
import SeasonFieldArray from '../common/SeasonFieldArray';
import { EMPTY_SEASON_TEMPLATE, TIERED_FORM_DEFAULT_VALUE } from './constants';
import GSTInclusionCheckbox from '../common/GSTInclusionCheckbox';
import { tieredFormSchema } from './validation-schema';
import useQuery from 'clipsal-cortex-utils-tmp/src/hooks/use-query';
import RouteChangeBlocker from '../../../../../common/components/RouteChangeBlocker';
import { customIsEqual } from '../../utils';

export default function TieredTariffForm() {
  const { tariffId } = useParams<{ tariffId: string }>();
  const site = useSelector(selectSite);
  const { isDesktopViewport } = useViewportType();
  const {
    formData: { tieredRates },
    onUpdateFormData,
  } = useTariffFormContext();
  const defaultValues = tieredRates ?? TIERED_FORM_DEFAULT_VALUE;
  const form = useForm<TieredTariffFormData>({
    resolver: yupResolver(tieredFormSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    setError,
    getValues,
    formState: { isSubmitting },
  } = form;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true, duration: 5_000 });
  const queryParams = useQuery();
  const backURL = queryParams.get('backURL');

  function handleSaveSeasons(values: SeasonFormData) {
    reset({
      shouldApplySeasons: values.shouldApplySeasons,
      seasons: values.seasons.map((s, seasonIndex) => {
        const fromMonth = s.fromDate.getMonth();
        const fromDate = s.fromDate.getDate();
        const toMonth = s.toDate.getMonth();
        const toDate = s.toDate.getDate();

        return {
          ...EMPTY_SEASON_TEMPLATE,
          seasonIndex: seasonIndex,
          name: s.name,
          fromMonth,
          fromDate,
          toMonth,
          toDate,
        };
      }),
    });
  }

  function handleSubmissionError() {
    toast({
      title: 'Invalid rates',
      description: 'Check that all required fields are filled out',
      status: 'error',
    });
  }

  function validateTieredRates(tieredTariffFormData: TieredTariffFormData) {
    let isValid = true;

    for (const [seasonIndex, season] of tieredTariffFormData.seasons.entries()) {
      for (const [tierIndex, tier] of season.tiers.entries()) {
        // The upper limit is required for all tiers except the last one.
        if ((!tier.upperLimitKWh || tier.upperLimitKWh < 0) && tierIndex !== season.tiers.length - 1) {
          setError(`seasons.${seasonIndex}.tiers.${tierIndex}.upperLimitKWh`, {
            type: 'required',
            message: 'This field is required',
          });

          isValid = false;
        }
      }
    }

    return isValid;
  }

  function handleSubmitTieredRateForm(tieredTariffFormData: TieredTariffFormData) {
    // Validating with a custom yup validator would require a lot of extra iteration to display erroneous fields, so
    // it happens in the submission function.
    if (!validateTieredRates(tieredTariffFormData)) {
      toast({
        title: 'Invalid rates',
        description: 'Check that all required fields are filled out',
        status: 'error',
      });
      return;
    }

    onUpdateFormData((p) => ({ ...p, tieredRates: tieredTariffFormData }));
    navigate(`/site/${site.site_id}/bills/tariffs/${tariffId}/review`);
  }

  function checkForUnsavedChanges() {
    if (isSubmitting) return false;
    return !customIsEqual(defaultValues, getValues());
  }

  return (
    <RouteChangeBlocker onChangeRoute={checkForUnsavedChanges}>
      <PageBase px={COMMON_BASE_PAGE_X_PADDING}>
        <FormProvider {...form}>
          <TopNav
            backURL={backURL ?? `/site/${site.site_id}/bills/tariffs/${tariffId}/basic-details`}
            title="Tiered Rate Configuration"
          />

          <Box px={5}>
            <Alert status="info" variant="left-accent">
              <AlertIcon />
              This will take about a minute. Please make sure you have your bill with you for reference.
            </Alert>
          </Box>

          <Heading ml={2} mt={3} size="sm">
            IMPORT RATES
          </Heading>

          <SeasonConfigurationDialogButton onOpen={onOpen} />
          <SeasonConfigurationFormDialog isOpen={isOpen} onClose={onClose} onSubmit={handleSaveSeasons} />

          <Box
            onSubmit={handleSubmit(handleSubmitTieredRateForm, handleSubmissionError)}
            data-testid="tiered-rates-form"
            as="form"
          >
            <FormContainer>
              {isDesktopViewport && (
                <Heading mb={2} size="md">
                  Configure Tiered Rates
                </Heading>
              )}
              <GSTInclusionCheckbox />

              <SeasonFieldArray tariffType="TIERED" />
            </FormContainer>

            <Center mt={6}>
              <Button
                data-testid="submit-tiered-tariff-form"
                type="submit"
                w={['75%', '75%', '50%']}
                rounded={20}
                colorScheme="dusk100"
              >
                Next
              </Button>
            </Center>
          </Box>
        </FormProvider>
      </PageBase>
    </RouteChangeBlocker>
  );
}
