import { Flex, Input, InputElementProps, useColorModeValue } from '@chakra-ui/react';
import React, { ClipboardEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';

const BASE_STYLES: InputElementProps = {
  w: '42px',
  h: '48px',
  p: '0',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: 'lg',
  borderRadius: 2,
};

interface OTPInputProps extends Omit<InputElementProps, 'onChange'> {
  noOfInputs?: number;
  isError?: boolean;
  isDisabled?: boolean;
  isNumeric?: boolean;
  isPrivate?: boolean;
  isPasteDisabled?: boolean;
  placeholder?: string;
  ariaLabel?: string;
  defaultValue?: string;
  onChange: (value: string) => void;
}

const checkNumeric = (char: string) => {
  return '0123456789'.includes(char);
};

const validateAndFixOtp = (noOfInputs: number, otp?: string) => {
  let otpCode = (otp || '').split('');
  const otpLength = otpCode.length;

  // if otp is longer than noOfInputs, we slice it
  if (otpLength > noOfInputs) {
    otpCode = otpCode.slice(0, noOfInputs);

    // if otp is shorter than noOfInputs, we fill it with empty string
  } else if (otpLength < noOfInputs) {
    const diff = noOfInputs - otpLength;
    for (let i = 0; i < diff; i++) {
      otpCode.push('');
    }
  }

  return otpCode;
};

export default function OTPInput(props: OTPInputProps) {
  const {
    ariaLabel,
    noOfInputs = 6,
    onChange,
    isDisabled,
    isNumeric = true,
    isPrivate,
    isError,
    isPasteDisabled,
    placeholder,
    defaultValue,
    ...rest
  } = props;

  const [input, setInput] = useState<string[]>(validateAndFixOtp(noOfInputs, defaultValue));
  const [activeInputIndex, setActiveInputIndex] = useState<number>(0);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    let inputString = '';

    input.forEach((c) => (inputString += c));

    onChange(inputString);
  }, [input]);

  useEffect(() => {
    inputRefs.current[activeInputIndex]?.focus();
  }, [activeInputIndex]);

  const moveToNextInput = () => {
    if (activeInputIndex !== noOfInputs - 1) {
      setActiveInputIndex(activeInputIndex + 1);
    }
  };

  const moveToPrevInput = () => {
    if (activeInputIndex !== 0) {
      setActiveInputIndex(activeInputIndex - 1);
    }
  };

  const clearCurrentFocusValue = () => {
    const clonedInput = [...input];
    clonedInput[activeInputIndex] = '';
    setInput(clonedInput);
  };

  const setCurrentFocusValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInputChar = e.target.value.slice(-1);

    if (isNumeric && !checkNumeric(newInputChar)) {
      return;
    }

    const clonedInput = [...input];
    clonedInput[activeInputIndex] = newInputChar;
    setInput(clonedInput);
    moveToNextInput();
  };

  const setActiveInputIndexOnFocus = (focusIndex: number) => {
    if (activeInputIndex !== focusIndex) {
      setActiveInputIndex(focusIndex);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Backspace':
        e.preventDefault();
        clearCurrentFocusValue();
        moveToPrevInput();
        break;
      case 'Space':
        e.preventDefault();
        break;
      case 'Spacebar':
        e.preventDefault();
        break;
      case ' ':
        e.preventDefault();
        break;
      case 'ArrowLeft':
        e.preventDefault();
        moveToPrevInput();
        break;
      case 'ArrowRight':
        e.preventDefault();
        moveToNextInput();
        break;
    }
  };

  const handleOnPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, noOfInputs - activeInputIndex)
      .split('')
      .filter((str) => (isNumeric && !checkNumeric(str) ? false : true));

    if (isDisabled || isPasteDisabled || pastedData.length === 0) {
      return;
    }

    const clonedInput: string[] = [...input];
    let clonedActiveInputIndex = activeInputIndex;

    input.forEach((_, i) => {
      if (pastedData.length > 0 && i >= activeInputIndex) {
        clonedInput[i] = pastedData.shift() || '';
        clonedActiveInputIndex++;
        if (clonedActiveInputIndex === noOfInputs) {
          clonedActiveInputIndex--;
        }
      }
    });
    setInput(clonedInput);
    setActiveInputIndex(clonedActiveInputIndex);
  };

  return (
    <Flex justify={'space-between'} w="100%" data-testid="otp-input-container">
      {input.map((_, i) => (
        <Input
          key={`otp-input-${i}`}
          data-testid={`otp-input-${i}`}
          id={`otp-input-${i}`}
          aria-label={`${ariaLabel || 'one time password input number'} ${i + 1}`}
          inputMode={isNumeric ? 'numeric' : 'text'}
          pattern={isNumeric ? '[0-9]*' : ''}
          bg={useColorModeValue('white', 'black')}
          {...BASE_STYLES}
          {...rest}
          onKeyDown={handleKeyDown}
          value={input[i] || ''}
          isDisabled={isDisabled}
          isInvalid={isError}
          onChange={setCurrentFocusValue}
          onFocus={() => {
            setActiveInputIndexOnFocus(i);
          }}
          placeholder={placeholder}
          onPaste={handleOnPaste}
          type={isPrivate ? 'password' : ''}
          ref={(ref: HTMLInputElement) => (inputRefs.current[i] = ref)}
        />
      ))}
    </Flex>
  );
}
