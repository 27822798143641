import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { HTTP } from '@awesome-cordova-plugins/http';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { addBearerToken } from './schneiderChargeSetupSlice';
import { EyeIcon, EyeSlashIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { useReduxDispatch } from '../../../app/store';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PIN_CODE, WIFI_DIRECT_BASE_URL } from './constants';
import TopNav from '../../../common/components/TopNav';
import InfoBottomDrawer from 'clipsal-cortex-ui/src/components/InfoBottomDrawer';
import InfoPopover from 'clipsal-cortex-ui/src/components/InfoPopover';
import Card from 'clipsal-cortex-ui/src/components/card/Card';
import PageBase from '../../../common/components/PageBase';
import schneiderChargeResetImg from '../../../assets/images/se_charge_reset.png';
import { useBulkPathSegmentReplace } from '../../../common/hooks/use-bulk-path-segment-replace';

const checkPinSchema = yup.object().shape({
  // string not number so it doesn't coerce leading 0s
  pin: yup.string().required('This is a required field').matches(/^\d+$/, 'PIN must be a number'),
});

type FormValues = { pin: string };

/* istanbul ignore next -- @preserve */
const getAccessToken = async (password: string) => {
  // login to get token
  const { data } = await HTTP.post(
    `${WIFI_DIRECT_BASE_URL}/evse/v1/login/login`,
    {
      username: 'esetup',
      password,
    },
    { 'Content-type': 'application/json' }
  );
  const parsedData = JSON.parse(data);
  return parsedData.token as string;
};

/* istanbul ignore next -- @preserve */
const postResetPin = async () =>
  await HTTP.post(
    `${WIFI_DIRECT_BASE_URL}/evse/v1/evsemanager/methods/resetpincode`,
    {},
    { 'Content-type': 'application/json' }
  );

export default function SchneiderChargeEnterPin() {
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true });
  const dispatch = useReduxDispatch();
  const {
    setError,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(checkPinSchema),
    defaultValues: { pin: '' },
  });
  const {
    isOpen: isPinResetConfirmationDrawerOpen,
    onOpen: onOpenPinResetConfirmationDrawer,
    onClose: onClosePinResetConfirmationDrawer,
  } = useDisclosure();
  const [homeWifiDetails, newPin, scanWifiQrCode] = useBulkPathSegmentReplace([
    'home_wifi_details',
    'new_pin',
    'scan_wifi_qr_code',
  ]);

  const [isPinVisible, setIsPinVisible] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isResetPending, setIsResetPending] = useState(false);
  const VisibleIcon = isPinVisible ? EyeSlashIcon : EyeIcon;

  const commonInputProps = useMemo(
    () => ({
      placeholder: 'From 6 to 16 digits',
      borderColor: '#9FA0A4',
      py: 6,
      inputMode: 'numeric' as const,
      type: isPinVisible ? 'number' : 'password',
    }),
    [isPinVisible]
  );

  const handleVisibleIconClick = () => setIsPinVisible(!isPinVisible);

  /* istanbul ignore next -- @preserve */
  const handleCheckExistingPin = useCallback(
    async (formData: FormValues) => {
      setIsRequestPending(true);

      // login to get token
      try {
        /* istanbul ignore next -- @preserve */
        const token = await getAccessToken(formData.pin);
        toast({
          title: 'PIN verified',
          status: 'success',
        });
        dispatch(addBearerToken(token));
        navigate(homeWifiDetails);
        return token;
      } catch (error) {
        console.error('Error verifying PIN', JSON.stringify(error));
        setError('pin', {
          message: 'Invalid PIN or failed connection. Double check your connection to the Schneider Charge network.',
        });
      }
      setIsRequestPending(false);
    },
    [toast, dispatch, navigate, homeWifiDetails, setError]
  );

  /* istanbul ignore next -- @preserve */
  const handleResetPin = useCallback(async () => {
    setIsRequestPending(true);
    setIsResetPending(true);

    try {
      // reset pincode
      await postResetPin();
      const token = await getAccessToken(DEFAULT_PIN_CODE);
      dispatch(addBearerToken(token));
      toast({
        title: 'PIN has been successfully reset!',
        status: 'success',
      });
      navigate(newPin);
    } catch (error) {
      toast({
        title: 'Something went wrong while resetting PIN',
        description: 'Please make sure you are connected to charger network!',
        status: 'error',
      });
    }
    setIsRequestPending(false);
    setIsResetPending(false);
  }, [dispatch, toast, navigate, newPin]);

  return (
    <PageBase>
      <TopNav title="Enter current PIN" backURL={scanWifiQrCode} />
      <Card p={8} as={'form'} onSubmit={handleSubmit(handleCheckExistingPin)}>
        <>
          <Text>
            Before you can add/update the Wi-Fi details, you need to verify your PIN.
            <InfoPopover>
              <Text fontSize="md">
                If configuring for the first time, this will be the pin sent to you by your installer.
              </Text>
            </InfoPopover>
          </Text>
          <Box mt={2}>
            <FormControl isInvalid={!!errors?.pin} my={12}>
              <FormLabel fontWeight={600}>Enter your PIN</FormLabel>
              <InputGroup>
                <Input data-testid={'existing-pin-input'} {...commonInputProps} {...register('pin')} />
                <InputRightElement h={12}>
                  <VisibleIcon onClick={handleVisibleIconClick} w={6} h={6} />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors?.pin?.message}</FormErrorMessage>
              <Flex>
                <Button
                  variant={'ghost'}
                  position="absolute"
                  colorScheme="customBlue"
                  fontWeight={'normal'}
                  onClick={onOpenPinResetConfirmationDrawer}
                  ml={0}
                  mt={2}
                  data-testid={'enter-pin-reset'}
                >
                  Reset PIN?
                </Button>
              </Flex>
            </FormControl>
          </Box>
        </>
        <Flex direction="row" justifyContent="center" alignItems="center" mt={16}>
          <Button
            variant="solid"
            size="lg"
            type="submit"
            isDisabled={isRequestPending}
            isLoading={isRequestPending}
            rounded={20}
            colorScheme="dusk100"
            minW={'50%'}
            data-testid={'enter-pin-next'}
          >
            Next
          </Button>
        </Flex>
      </Card>
      <InfoBottomDrawer
        onClose={!isResetPending ? onClosePinResetConfirmationDrawer : () => undefined}
        isOpen={isPinResetConfirmationDrawerOpen}
        header="Confirm Pin Reset"
        isClosingDisabled={isResetPending}
      >
        <InfoBottomDrawer.Content>
          <VStack>
            <Text mt={4} fontSize={'lg'}>
              {!isResetPending
                ? // eslint-disable-next-line max-len
                  'Are you sure you want to reset your PIN? This will reset it to the default value and you will have to set a new one.'
                : 'To complete the reset, press the PUSH button once.'}
            </Text>
            {isResetPending && (
              <Image src={schneiderChargeResetImg} alt="Schneider Charge Reset Pin" my={4} w="100%" maxW={250} />
            )}
          </VStack>
        </InfoBottomDrawer.Content>
        <InfoBottomDrawer.ConfirmButton
          onClick={handleResetPin}
          text="Proceed with Reset"
          isDisabled={isResetPending}
          isLoading={isResetPending}
          data-testid={'enter-pin-confirm-reset'}
        />
      </InfoBottomDrawer>
    </PageBase>
  );
}
