import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  IconButton,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { TOURateType, TOUTariffFormData } from './types';
import ControlledTimeSelectButton, { ControlledTimeSelectButtonFormPath } from './ControlledTimeSelectButton';
import { EMPTY_TIME_OF_USE_TEMPLATE } from './constants';
import { DeleteIcon } from '@chakra-ui/icons';
import TOUPeriodSelect, { TOUPeriodSelectFormPath } from './TOUPeriodSelect';
import InfoPopover from 'clipsal-cortex-ui/src/components/InfoPopover';

type Props = {
  formPath: RateFormPath;
};

type RateProperty = 'shoulderRates' | 'peakRate' | 'offPeakRate';
type RateFormPath = `seasons.${number}.${`shoulderRates.${number}` | `peakRate` | `offPeakRate`}`;

const RATE_PROPERTY_TO_TYPE: Record<RateProperty, TOURateType> = {
  shoulderRates: 'SHOULDER',
  offPeakRate: 'OFF-PEAK',
  peakRate: 'PEAK',
};

export default function TimeOfUseFieldArray({ formPath }: Props) {
  const touFormPath = `${formPath}.timesOfUse` as const;
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<TOUTariffFormData>();
  const {
    fields: timesOfUse,
    remove,
    append,
  } = useFieldArray({
    control,
    name: touFormPath,
  });
  const [, seasonIndex, rateProperty, rateIndex] = formPath.split('.');
  const appliesAtAllOtherTimes = watch(`${formPath}.appliesAtAllOtherTimes`);
  const ratesForSeason = watch(`seasons.${Number(seasonIndex)}`);
  const errorsForSeason = errors?.seasons?.[Number(seasonIndex)];
  const rateType = RATE_PROPERTY_TO_TYPE[rateProperty as RateProperty];

  function checkIfRateAppliesAtAllOtherTimes() {
    // Only one rate can apply at other times, so we don't show the option for other rates if it has been selected
    // for any. This unfortunately requires a watch on all values for this season.
    const allRates = [...ratesForSeason.shoulderRates];
    if (ratesForSeason.peakRate) allRates.push(ratesForSeason.peakRate);
    if (ratesForSeason.offPeakRate) allRates.push(ratesForSeason.offPeakRate);

    const rateWhichAppliesAtAllOtherTimes = allRates.find((rate) => rate.appliesAtAllOtherTimes);
    // No rates apply at all other times
    if (!rateWhichAppliesAtAllOtherTimes) return false;

    // Make sure the rate which applies at other times is not this rate.
    // The comparison logic gets tricky when we're dealing with shoulder rates.
    if (rateType === 'SHOULDER' && rateWhichAppliesAtAllOtherTimes.type === 'SHOULDER') {
      const indexOfShoulderRateWhichAppliesAtAllOtherTimes = ratesForSeason.shoulderRates.findIndex(
        (shoulderRate) => shoulderRate.appliesAtAllOtherTimes
      );
      if (indexOfShoulderRateWhichAppliesAtAllOtherTimes === -1) {
        return false;
      } else {
        return indexOfShoulderRateWhichAppliesAtAllOtherTimes !== Number(rateIndex);
      }
    } else {
      return rateWhichAppliesAtAllOtherTimes.type !== rateType;
    }
  }

  return (
    <Box mt={3}>
      <Heading size="sm">Time of Use</Heading>
      <Controller
        name={`${formPath}.appliesAtAllOtherTimes`}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Checkbox
            data-testid={`season-${seasonIndex}-${rateType
              .toLowerCase()
              .replace('_', '-')}-rate-applies-at-all-other-times`}
            hidden={checkIfRateAppliesAtAllOtherTimes()}
            onChange={onChange}
            ref={ref}
            mt={2}
            isChecked={value}
          >
            Applies at all other times?{' '}
            <InfoPopover
              triggerContainerProps={{
                onClick: (e) => {
                  // Ensure clicking the popover trigger doesn't check or uncheck the checkbox
                  e.preventDefault();
                },
              }}
            >
              By selecting this option, this rate will apply at all times other than the times you have specified for
              your other rates.
            </InfoPopover>
          </Checkbox>
        )}
      />
      <Divider mt={3} />
      <Box opacity={appliesAtAllOtherTimes ? 0.4 : 1}>
        {timesOfUse.map((timeOfUse, timeOfUseIndex) => {
          const errorsForRate =
            rateType === 'SHOULDER'
              ? errorsForSeason?.shoulderRates?.[Number(rateIndex)]
              : errorsForSeason?.[rateProperty as Exclude<RateProperty, 'shoulderRates'>];
          const errorsForTimeOfUse = errorsForRate?.timesOfUse?.[timeOfUseIndex];
          const dataTestIdPrefix =
            rateType === 'SHOULDER'
              ? `season-${seasonIndex}-shoulder-rate-${rateIndex}-time-of-use-${timeOfUseIndex}`
              : `season-${seasonIndex}-${rateType.toLowerCase()}-rate-time-of-use-${timeOfUseIndex}`;

          return (
            <Box mt={3} key={timeOfUse.id}>
              <Flex align="center" justify={['space-between', 'space-between', 'flex-start']}>
                <Box display={['block', 'block', 'flex']} alignItems="center">
                  <Flex align="center">
                    <Text mr={2}>From</Text>
                    <ControlledTimeSelectButton
                      data-testid={dataTestIdPrefix + '-from-time'}
                      isDisabled={appliesAtAllOtherTimes}
                      isError={errorsForSeason?.type === 'cover-all-times'}
                      type="START"
                      formPath={(touFormPath + `.${timeOfUseIndex}.fromTime`) as ControlledTimeSelectButtonFormPath}
                    />
                    <Text mx={2}>to</Text>
                    <ControlledTimeSelectButton
                      data-testid={dataTestIdPrefix + '-to-time'}
                      isDisabled={appliesAtAllOtherTimes}
                      isError={errorsForSeason?.type === 'cover-all-times'}
                      type="END"
                      formPath={(touFormPath + `.${timeOfUseIndex}.toTime`) as ControlledTimeSelectButtonFormPath}
                    />
                  </Flex>

                  <Flex ml={[0, 0, 2]} mt={[2, 2, 0]} align="center">
                    <Text mr={2}>on</Text>
                    <FormControl isInvalid={!!errorsForTimeOfUse?.applicablePeriods}>
                      <TOUPeriodSelect
                        data-testid={dataTestIdPrefix + '-period-select'}
                        formPath={(touFormPath + `.${timeOfUseIndex}.applicablePeriods`) as TOUPeriodSelectFormPath}
                        isDisabled={appliesAtAllOtherTimes}
                      />
                      <FormErrorMessage
                        data-testid={`season-${seasonIndex}-rate-${
                          rateType === 'SHOULDER' ? 'shoulder-rate-' + rateIndex : rateProperty
                        }-period-error-message`}
                      >
                        {errorsForTimeOfUse?.applicablePeriods?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                </Box>

                {timeOfUseIndex === 0 ? (
                  <Button
                    isDisabled={appliesAtAllOtherTimes}
                    ml={[0, 0, 2]}
                    onClick={() => append(EMPTY_TIME_OF_USE_TEMPLATE)}
                    variant="ghost"
                    color="customBlue.500"
                    size={'sm'}
                    data-testid={`${dataTestIdPrefix}-add-time-of-use-btn`}
                    aria-label={`Add ${rateType} ${rateType === 'SHOULDER' && Number(rateIndex) + 1} time of use ${
                      Number(timeOfUseIndex) + 1
                    } for season ${Number(seasonIndex) + 1}`}
                  >
                    Add
                  </Button>
                ) : (
                  <IconButton
                    isDisabled={appliesAtAllOtherTimes}
                    ml={[0, 0, 2]}
                    size={'sm'}
                    onClick={() => remove(timeOfUseIndex)}
                    color="customRed.500"
                    variant="ghost"
                    icon={<DeleteIcon />}
                    aria-label={`Delete ${rateType} ${rateType === 'SHOULDER' && rateIndex + 1} time of use ${
                      timeOfUseIndex + 1
                    } for season ${seasonIndex + 1}`}
                    data-testid={`${dataTestIdPrefix}-delete-time-of-use-btn`}
                  />
                )}
              </Flex>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
