import { ButtonProps, Button } from '@chakra-ui/react';
import React from 'react';
import { AddIcon } from '@chakra-ui/icons';

export default function CustomAddButton(props: ButtonProps) {
  return (
    <Button color="customBlue.500" variant="ghost" align="center" type="button" {...props}>
      <AddIcon h={4} w={4} mr={2} />
      {props.children}
    </Button>
  );
}
