import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback, useMemo, useState } from 'react';
import { HTTP } from '@awesome-cordova-plugins/http';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { addBearerToken, selectBearerToken } from './schneiderChargeSetupSlice';
import { EyeIcon, EyeSlashIcon } from 'clipsal-cortex-icons/src/custom-icons';
import { useReduxDispatch } from '../../../app/store';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PIN_CODE, WIFI_DIRECT_BASE_URL, WIFI_DIRECT_HOST } from './constants';
import TopNav from '../../../common/components/TopNav';
import Card from 'clipsal-cortex-ui/src/components/card/Card';
import PageBase from '../../../common/components/PageBase';
import { useBulkPathSegmentReplace } from '../../../common/hooks/use-bulk-path-segment-replace';

const createPinSchema = yup.object().shape({
  // string not number so it doesn't coerce leading 0s
  newPin: yup
    .string()
    .matches(/^\d+$/, 'PIN must be a number')
    .test('min', 'PIN must be at least 6 digits long', (val) => !!val && val.length >= 6)
    .test('max', 'PIN must be at most 16 digits long', (val) => !!val && val.length <= 16),
  confirmPin: yup
    .string()
    .matches(/^\d+$/, 'PIN must be a number')
    .test('min', 'PIN must be at least 6 digits long', (val) => !!val && val.length >= 6)
    .test('max', 'PIN must be at most 16 digits long', (val) => !!val && val.length <= 16)
    .test('match', 'PIN must match new PIN', function (value) {
      return this.parent.newPin === value;
    }),
});

type FormValues = { newPin: string; confirmPin: string };

/* istanbul ignore next -- @preserve */
const getAccessToken = async (password: string) => {
  // login to get token
  const { data } = await HTTP.post(
    `${WIFI_DIRECT_BASE_URL}/evse/v1/login/login`,
    {
      username: 'esetup',
      password,
    },
    { 'Content-type': 'application/json' }
  );
  const parsedData = JSON.parse(data);
  return parsedData.token as string;
};

/* istanbul ignore next -- @preserve */
const putPin = async (newPin: string, token: string | null) =>
  await HTTP.put(
    `${WIFI_DIRECT_BASE_URL}/evse/v1/login/updatepassword`,
    {
      oldpassword: DEFAULT_PIN_CODE,
      newpassword: newPin,
    },
    {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
      Host: WIFI_DIRECT_HOST,
    }
  );

export default function SchneiderChargeNewPin() {
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true });
  const dispatch = useReduxDispatch();
  const bearerToken = useSelector(selectBearerToken);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(createPinSchema),
    defaultValues: { newPin: '', confirmPin: '' },
  });
  const [homeWifiDetails, enterPin] = useBulkPathSegmentReplace(['home_wifi_details', 'enter_pin']);

  const [isPinVisible, setIsPinVisible] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const VisibleIcon = isPinVisible ? EyeSlashIcon : EyeIcon;

  const commonInputProps = useMemo(
    () => ({
      placeholder: 'From 6 to 16 digits',
      borderColor: '#9FA0A4',
      py: 6,
      inputMode: 'numeric' as const,
      type: isPinVisible ? 'number' : 'password',
    }),
    [isPinVisible]
  );

  const handleVisibleIconClick = () => setIsPinVisible(!isPinVisible);

  const handleUpdatePin = useCallback(
    async (formData: FormValues) => {
      setIsRequestPending(true);
      try {
        await putPin(formData.newPin, bearerToken);
        const token = await getAccessToken(formData.newPin);
        dispatch(addBearerToken(token));
        toast({
          title: 'PIN has been successfully updated!',
          status: 'success',
        });
        navigate(homeWifiDetails);
      } catch (error) {
        toast({
          title: 'Something went wrong creating a new PIN',
          description: 'Please make sure you use a complex PIN, e.g. 123.. or 654.. will not be accepted.',
          status: 'error',
        });
      }
      setIsRequestPending(false);
    },
    [bearerToken, dispatch, navigate, toast, homeWifiDetails]
  );

  return (
    <PageBase>
      <TopNav title="Set your new PIN" backURL={enterPin} />
      <Card p={8} as={'form'} onSubmit={handleSubmit(handleUpdatePin)}>
        <Box mb={4}>
          <Text>
            This PIN allows you to login to your charger's Wi-Fi direct access point and change some of your charger's
            settings.
          </Text>
          <Text my={4}>Please save this PIN to login to your charger's Wi-Fi direct access point in the future.</Text>
          <FormControl isInvalid={!!errors?.newPin}>
            <FormLabel fontWeight={600}>New PIN</FormLabel>
            <InputGroup>
              <Input data-testid={'new-pin-input'} {...commonInputProps} {...register('newPin')} />
              <InputRightElement h={12}>
                <VisibleIcon onClick={handleVisibleIconClick} w={6} h={6} />
              </InputRightElement>{' '}
            </InputGroup>
            <FormErrorMessage>{errors?.newPin?.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box mb={4}>
          <FormControl isInvalid={!!errors?.confirmPin}>
            <FormLabel fontWeight={600}>Confirm PIN</FormLabel>
            <InputGroup>
              <Input data-testid={'confirm-pin-input'} {...commonInputProps} {...register('confirmPin')} />
              <InputRightElement h={12}>
                <VisibleIcon onClick={handleVisibleIconClick} w={6} h={6} />
              </InputRightElement>{' '}
            </InputGroup>
            <FormErrorMessage>{errors?.confirmPin?.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <Flex direction="row" justifyContent="center" alignItems="center" mt={12}>
          <Button
            variant="solid"
            size="lg"
            type="submit"
            isDisabled={isRequestPending || !bearerToken}
            isLoading={isRequestPending}
            rounded={20}
            colorScheme="dusk100"
            minW={'50%'}
          >
            Next
          </Button>
        </Flex>
      </Card>
    </PageBase>
  );
}
