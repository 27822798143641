import React from 'react';
import { Alert, AlertIcon, Box, Button, Center, Heading, useDisclosure, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { TOUTariffFormData } from './types';
import SeasonFieldArray from '../common/SeasonFieldArray';
import { EMPTY_SEASON_TEMPLATE, TOU_FORM_DEFAULT_VALUE } from './constants';
import TopNav from '../../../../../common/components/TopNav';
import { useSelector } from 'react-redux';
import { selectSite } from '../../../../site/siteSlice';
import { useNavigate, useParams } from 'react-router-dom';
import PageBase from '../../../../../common/components/PageBase';
import useViewportType from '../../../../../common/hooks/use-viewport-type';
import SeasonConfigurationDialogButton from '../common/SeasonConfigurationDialogButton';
import { touFormSchema } from './validation-schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { SeasonFieldError } from '../common/types';
import { useTariffFormContext } from '../../tariff-form-context';
import SeasonConfigurationFormDialog, { SeasonFormData } from '../common/SeasonConfigurationFormDialog';
import { COMMON_BASE_PAGE_X_PADDING } from '../../constants';
import FormContainer from '../../FormContainer';
import GSTInclusionCheckbox from '../common/GSTInclusionCheckbox';
import useQuery from 'clipsal-cortex-utils-tmp/src/hooks/use-query';
import RouteChangeBlocker from '../../../../../common/components/RouteChangeBlocker';
import { customIsEqual } from '../../utils';

export default function TOURateForm() {
  const { tariffId } = useParams<{ tariffId: string }>();
  const site = useSelector(selectSite);
  const { isDesktopViewport } = useViewportType();
  const {
    formData: { touRates: touFormData },
    onUpdateFormData,
  } = useTariffFormContext();
  const defaultValues = touFormData ?? TOU_FORM_DEFAULT_VALUE;
  const form = useForm<TOUTariffFormData>({
    resolver: yupResolver(touFormSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    getValues,
    formState: { isSubmitting },
  } = form;
  const { onOpen, onClose, isOpen } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast({ isClosable: true, duration: 5_000 });
  const queryParams = useQuery();
  const backURL = queryParams.get('backURL');

  async function handleSubmitTOUForm(touRates: TOUTariffFormData) {
    onUpdateFormData((p) => ({ ...p, touRates }));

    navigate(`/site/${site.site_id}/bills/tariffs/${tariffId}/review`);
  }

  async function handleSubmissionError(errors: FieldErrors<TOUTariffFormData>) {
    const seasonErrors = errors?.seasons;

    if (seasonErrors) {
      const overlapErrorIndex = (seasonErrors as SeasonFieldError[]).findIndex((s) => s?.type === 'cover-all-times');
      if (overlapErrorIndex !== -1) {
        const { seasons } = getValues();
        let title = 'Times of use are invalid!';
        if (seasons.length > 1) title = `Season "${seasons[overlapErrorIndex].name}" has invalid times of use.`;

        toast({
          title,
          description: 'Check that there is no overlap or time gaps in your specified times of use.',
          status: 'error',
        });
        return;
      }

      const noRatesAssignedError = (seasonErrors as SeasonFieldError[]).find((s) => s?.type === 'minimum-one-rate');
      if (!!noRatesAssignedError) {
        toast({
          title: 'At least one rate is required',
          description: 'You must have at least one shoulder, peak or off-peak rate',
          status: 'error',
        });
        return;
      }

      toast({
        title: 'Invalid rates',
        description: 'Check that all required fields are filled out',
        status: 'error',
      });
    }
  }

  async function handleSaveSeasons(values: SeasonFormData) {
    const existingSeasonValues = getValues().seasons;
    let seasons = values.seasons.map((s, seasonIndex) => {
      const existingValues = existingSeasonValues[seasonIndex];
      const fromMonth = s.fromDate.getMonth();
      const fromDate = s.fromDate.getDate();
      const toMonth = s.toDate.getMonth();
      const toDate = s.toDate.getDate();

      const seasonData = existingValues ?? EMPTY_SEASON_TEMPLATE;

      return {
        ...seasonData,
        seasonIndex: seasonIndex,
        name: s.name,
        fromMonth,
        fromDate,
        toMonth,
        toDate,
      };
    });

    // If the user had more than one existing season and removed seasonality from the rates, use the rate values from
    // their first season but set it to apply all year round.
    if (!values.shouldApplySeasons && existingSeasonValues.length > 1) {
      const firstSeason = existingSeasonValues[0];
      seasons = [
        {
          ...EMPTY_SEASON_TEMPLATE,
          name: firstSeason.name,
          peakRate: firstSeason.peakRate,
          offPeakRate: firstSeason.offPeakRate,
          shoulderRates: firstSeason.shoulderRates,
        },
      ];
    }

    reset({
      shouldApplySeasons: values.shouldApplySeasons,
      seasons,
    });
  }

  function checkForUnsavedChanges() {
    if (isSubmitting) return false;
    return !customIsEqual(defaultValues, getValues());
  }

  return (
    <RouteChangeBlocker onChangeRoute={checkForUnsavedChanges}>
      <PageBase px={COMMON_BASE_PAGE_X_PADDING}>
        <FormProvider {...form}>
          <TopNav
            backURL={backURL ?? `/site/${site.site_id}/bills/tariffs/${tariffId}/basic-details`}
            title="TOU Rate Configuration"
          />

          <Box px={5}>
            <Alert status="info" variant="left-accent">
              <AlertIcon />
              This will take about a minute. Please make sure you have your bill with you for reference.
            </Alert>
          </Box>

          <Heading ml={2} mt={3} size="sm">
            IMPORT RATES
          </Heading>

          <SeasonConfigurationDialogButton onOpen={onOpen} />
          <SeasonConfigurationFormDialog isOpen={isOpen} onClose={onClose} onSubmit={handleSaveSeasons} />

          <FormContainer>
            {isDesktopViewport && (
              <Heading mb={2} size="md">
                Configure Time of Use Rates
              </Heading>
            )}
            <GSTInclusionCheckbox />

            <Box
              as="form"
              onSubmit={handleSubmit(handleSubmitTOUForm, handleSubmissionError)}
              data-testid="tou-rates-form"
            >
              <SeasonFieldArray tariffType="TOU" />

              <Center mt={6}>
                <Button
                  data-testid="submit-tou-tariff-form"
                  type="submit"
                  w={['75%', '75%', '50%']}
                  rounded={20}
                  colorScheme="dusk100"
                >
                  Next
                </Button>
              </Center>
            </Box>
          </FormContainer>
        </FormProvider>
      </PageBase>
    </RouteChangeBlocker>
  );
}
