import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box, SimpleGrid } from '@chakra-ui/react';
import useQuery from 'clipsal-cortex-utils-tmp/src/hooks/use-query';
import { BOTTOM_NAV_HEIGHT, PAGE_SPACING_PER_VIEWPORT } from '../../common/constants';
import EnergyPlan from './energy-plan/EnergyPlan';
import CurrentBillPeriod from './CurrentBillPeriod';
import PastBills from './past-bills/PastBills';
import TariffsList from './tariffs/TariffsList';
import { UploadBillBottomDrawer, UploadBillModal } from './energy-plan/UploadBill';
import { BillsOutletContext } from './SiteBills';
import { billsApi } from './billsApi';
import { useReduxDispatch } from '../../app/store';
import useViewportType from '../../common/hooks/use-viewport-type';

export default function BillsHome() {
  const { tariffs, isParentLoaded, onOpenUploadBillDialog, isOpenUploadBillDialog, onCloseUploadBillDialog } =
    useOutletContext<BillsOutletContext>();
  const { isDesktopViewport } = useViewportType();
  const dispatch = useReduxDispatch();
  // Handlers for bill upload dialog (bottom drawer on mobile, modal on desktop)
  const billUrl = useQuery().get('bill-url');

  useEffect(() => {
    if (billUrl) onOpenUploadBillDialog();
  }, [billUrl, onOpenUploadBillDialog]);

  async function handleReset() {
    dispatch(billsApi.util.resetApiState());
  }

  return (
    <>
      <Box
        data-testid="site-bills"
        mb={isDesktopViewport ? 4 : BOTTOM_NAV_HEIGHT}
        mt={4}
        mx={PAGE_SPACING_PER_VIEWPORT}
      >
        <EnergyPlan onOpenUploadBillDialog={onOpenUploadBillDialog} isParentLoaded={isParentLoaded} />
        <SimpleGrid gridGap={5} pt={5} columns={[1, 1, 2]}>
          {!!tariffs?.results?.length && <CurrentBillPeriod isParentLoaded={isParentLoaded} />}
          <PastBills onOpenUploadBillDialog={onOpenUploadBillDialog} isParentLoaded={isParentLoaded} />
          <TariffsList isParentLoaded={isParentLoaded} />
        </SimpleGrid>
      </Box>

      {isDesktopViewport ? (
        <UploadBillModal onResetBills={handleReset} isOpen={isOpenUploadBillDialog} onClose={onCloseUploadBillDialog} />
      ) : (
        <UploadBillBottomDrawer
          onResetBills={handleReset}
          isOpen={isOpenUploadBillDialog}
          onClose={onCloseUploadBillDialog}
          billUrl={billUrl}
        />
      )}
    </>
  );
}
