import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { checkUserLoggedIn, fetchUserDetails } from '../../features/user/userSlice';
import { IS_NOT_DEVELOPMENT, IS_PRODUCTION } from '../constants';
import { useReduxDispatch } from '../../app/store';

// Configures the app to be ready for use.
export const useAppReady = () => {
  const [isLoaded, setLoaded] = useState(false);
  const dispatch = useReduxDispatch();

  useEffect(() => {
    async function fetch() {
      const isLoggedIn = unwrapResult(await dispatch(checkUserLoggedIn()));

      if (isLoggedIn) {
        await dispatch(
          fetchUserDetails({
            isProduction: IS_PRODUCTION,
            isNotDevelopment: IS_NOT_DEVELOPMENT,
          })
        );
      }

      setLoaded(true);
    }

    fetch();
  }, [dispatch]);

  return isLoaded;
};
