import { useSelector } from 'react-redux';
import { Box, Button, Divider, Flex, Grid, Heading, Image, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import retailersStackedImg from '../../../assets/images/energy_plan_retailers_stacked.svg';
import rpcMockDesktopImg from '../../../assets/images/rpc_mock_desktop.svg';
import rpcMockDesktopImgDark from '../../../assets/images/rpc_mock_desktop_dark.svg';
import rpcMockMobileImg from '../../../assets/images/rpc_mock_mobile.svg';
import rpcMockMobileImgDark from '../../../assets/images/rpc_mock_mobile_dark.svg';
import { CommonEnergyPlanProps } from './EnergyPlan';
import { useViewportType } from '../../../common/hooks/use-viewport-type';
import TourPopover from '../../../common/components/TourPopover';
import { BILLS_TOUR_STEPS, CONSUMPTION_ONLY_BILLS_TOUR_STEPS } from './bills-tour-steps';
import { selectSite } from '../../site/siteSlice';
import { BillsStatus, useGetSiteBillsStatusQuery } from '../billsApi';
import { IS_DEMO_LOGIN } from '../../../common/constants';

export function EnergyPlanUploadBillSection({ isParentLoaded, onOpenUploadBillDialog }: CommonEnergyPlanProps) {
  const site = useSelector(selectSite);
  const { data: billsStatus, isLoading: isBillsStatusLoading } = useGetSiteBillsStatusQuery(site.site_id);

  // Loading state managed by parent component
  if (isBillsStatusLoading) return <></>;

  const uploadBillContents = (
    <>
      <Divider my={4} borderColor={'textGrey.500'} borderWidth={1} />
      <UploadBillNotificationSection onOpenUploadBillDialog={onOpenUploadBillDialog} isParentLoaded={isParentLoaded} />
    </>
  );

  if (!billsStatus!.status || ['NT', 'WA'].includes(site.state)) return <></>;

  const statusToJSX: Record<BillsStatus, React.JSX.Element | null> = {
    [BillsStatus.NewBillExpected]: uploadBillContents, // @TODO: can this ever happen if there's no existing RPC?
    [BillsStatus.NoActionRequired]: null,
    [BillsStatus.NoBillReceived]: uploadBillContents,
  };

  return <Box>{statusToJSX[billsStatus!.status!]}</Box>;
}

function UploadBillNotificationSection({ isParentLoaded, onOpenUploadBillDialog }: CommonEnergyPlanProps) {
  const { isDesktopViewport } = useViewportType();
  const desktopImg = useColorModeValue(rpcMockDesktopImg, rpcMockDesktopImgDark);
  const mobileImg = useColorModeValue(rpcMockMobileImg, rpcMockMobileImgDark);
  const [isMobileIMGLoaded, setIsMobileIMGLoaded] = useState(false);
  const site = useSelector(selectSite);
  const tourSteps = site.is_consumption_site ? CONSUMPTION_ONLY_BILLS_TOUR_STEPS : BILLS_TOUR_STEPS;

  return (
    <Grid fontSize={['sm', 'sm', 'md']} mt={4} templateColumns={['1fr', '1fr', '100px 0.4fr 0.6fr']}>
      <Flex>
        <Skeleton alignSelf={'flex-start'} width={'80%'} isLoaded={isParentLoaded}>
          {isDesktopViewport && <Image width={'100%'} src={retailersStackedImg} alt={'Stacked retailer images'} />}
        </Skeleton>
      </Flex>

      <Flex direction={'column'}>
        {!isDesktopViewport && (
          <Skeleton mb={3} width={'100%'} isLoaded={isMobileIMGLoaded && isParentLoaded}>
            <Image
              w={'100%'}
              src={mobileImg}
              alt={'RPC mockup image'}
              onLoad={() => {
                setIsMobileIMGLoaded(true);
              }}
              loading={'eager'}
            />
          </Skeleton>
        )}

        <Flex justify={'space-between'}>
          {!isDesktopViewport && (
            <Skeleton width={'20%'} isLoaded={isParentLoaded}>
              <Image src={retailersStackedImg} alt={'Stacked retailer images'} loading={'eager'} />
            </Skeleton>
          )}
          <Skeleton isLoaded={isParentLoaded}>
            <Heading size={'sm'}>Save more on your bills by comparing plans!</Heading>
          </Skeleton>
        </Flex>

        <Skeleton isLoaded={isParentLoaded}>
          <Text my={2} color={'textGrey.500'}>
            Upload your most recent PDF bill and we will compare it to other popular plans to save you money.
          </Text>
        </Skeleton>

        <Button
          className={'tour-bills-step-one'}
          onClick={onOpenUploadBillDialog}
          alignSelf={['center', 'center', 'flex-start']}
          mb={2}
          rounded={20}
          colorScheme="dusk100"
          w={['80%', '80%', 'inherit']}
          isDisabled={IS_DEMO_LOGIN}
        >
          Upload Bill
        </Button>
      </Flex>

      {isDesktopViewport && (
        <Skeleton width={'100%'} isLoaded={isParentLoaded}>
          <Image width={'100%'} src={desktopImg} alt={'RPC mockup image'} />
        </Skeleton>
      )}

      {/* Wait for mobile image to load before rendering popover as it causes layout shift */}
      {!isDesktopViewport && isMobileIMGLoaded && isParentLoaded && (
        <TourPopover localStorageFlag={'isBillsTourDismissed'} tourSteps={tourSteps} />
      )}
      {isDesktopViewport && isParentLoaded && (
        <TourPopover localStorageFlag={'isBillsTourDismissed'} tourSteps={tourSteps} />
      )}
    </Grid>
  );
}
