import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Image, Text, useToast, VStack } from '@chakra-ui/react';
import { useActivateMutation } from '../evChargersApi';
import { didUserGrantBarcodePermission } from 'clipsal-cortex-utils/src/common';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { IS_NATIVE } from 'clipsal-cortex-utils/src/constants/common-constants';
import { useSelector } from 'react-redux';
import { selectSite } from '../../site/siteSlice';
import PageBase from '../../../common/components/PageBase';
import TopNav from '../../../common/components/TopNav';
import schneiderChargeDeviceQRImg from '../../../assets/images/se_charge_device_qr.png';
import { BOTTOM_NAV_HEIGHT, IS_RUNNING_CYPRESS_TESTS } from '../../../common/constants';
import CircularLoader from '../../../common/components/CircularLoader';
import { useBulkPathSegmentReplace } from '../../../common/hooks/use-bulk-path-segment-replace';

const MANUFACTURER_NAME = 'Schneider Electric';

export default function SchneiderChargeActivate() {
  const { site_id: siteId } = useSelector(selectSite);
  const navigate = useNavigate();
  const toast = useToast();
  const [activate] = useActivateMutation();
  const [setupSuccess, setupUnavailableFail, setupGenericFail, homeWifiDetails] = useBulkPathSegmentReplace([
    'setup_success',
    'setup_unavailable_fail',
    'setup_generic_fail',
    'home_wifi_details',
  ]);

  const [isRequestPending, setIsRequestPending] = useState(false);
  const isTestingBarcodeScanError = (window as any).isTestingBarcodeScanError;

  const showInvalidQRCodeToast = useCallback(() => {
    document.body.classList.remove('qrscanner');
    setIsRequestPending(false);
    toast({
      title: 'Invalid QR code!',
      description: 'Please try again! If issue persists, please contact support.',
      status: 'error',
      isClosable: true,
    });
    return null;
  }, [toast]);

  const getBarcodeValues = useCallback(
    (barcodeContent: string) => {
      // barcode content sample:
      // http://go2se.com/ref=EVH4A10N5PU/sn=SN2205278845/cpid=b5eddbaf-984f-418e-88eb-cf0b8ff3e775
      if (!barcodeContent) return showInvalidQRCodeToast();

      const splittedUrl = barcodeContent.split('.com/');
      const chargerProperties = splittedUrl[1];

      if (!chargerProperties) return showInvalidQRCodeToast();

      const splitChargerProperties = chargerProperties.split('/');

      return splitChargerProperties.reduce(
        (acc, curr) => {
          const [key, value] = curr.split('=');
          if (['ref', 'sn', 'cpid'].includes(key)) return { ...acc, [key]: value };
          return acc;
        },
        { ref: '', sn: '', cpid: '' }
      );
    },
    [showInvalidQRCodeToast]
  );

  /* istanbul ignore next -- @preserve */
  const handleStartQRCodeScan = useCallback(async () => {
    setIsRequestPending(true);
    // Handle barcode scan success and fail when running Cypress tests
    if (IS_RUNNING_CYPRESS_TESTS) {
      if (isTestingBarcodeScanError) return showInvalidQRCodeToast();
      await activate({
        siteId,
        body: {
          model_number: 'RVH4B10I2PJ',
          serial_number: 'EVB1A33P4KI3N214912002400150027AZ',
          charger_id: '0586996e-3d14-403d-9b71-31012010e7f21',
          manufacturer_name: MANUFACTURER_NAME,
        },
      }).unwrap();
      setIsRequestPending(false);
      return;
    }

    try {
      const hasPermission = await didUserGrantBarcodePermission();

      if (hasPermission) {
        await BarcodeScanner.hideBackground();
        document.body.classList.add('qrscanner');

        const result = await BarcodeScanner.startScan();
        if (result.hasContent) {
          const barCodeValues = getBarcodeValues(result.content);
          if (!barCodeValues) return;

          document.body.classList.remove('qrscanner');
          try {
            await activate({
              siteId,
              body: {
                model_number: barCodeValues.ref,
                serial_number: barCodeValues.sn,
                charger_id: barCodeValues.cpid,
                manufacturer_name: MANUFACTURER_NAME,
              },
            }).unwrap();
            toast({
              title: 'Schneider Charge Configured!',
              description:
                'You have successfully configured your Schneider Charge. It should now be available on the live page.',
              status: 'success',
              isClosable: true,
            });
            navigate(setupSuccess);
          } catch (error) {
            const message = (error as any)?.response?.data.message || '';
            if (message?.includes('Not support EVSE charger model')) {
              toast({ title: 'This device model is not supported!', status: 'error', isClosable: true });
              navigate(setupUnavailableFail);
            } else {
              toast({
                title: 'Failed to activate Schneider Charge',
                status: 'error',
                isClosable: true,
              });
              navigate(setupGenericFail);
            }
            console.error('Error activating Schneider Charge', error);
          }
        } else {
          console.error('No content from QR Code');
          showInvalidQRCodeToast();
        }
      }
    } catch (error) {
      console.error('Error scanning QR Code', error);
      showInvalidQRCodeToast();
    }
    setIsRequestPending(false);
  }, [
    activate,
    getBarcodeValues,
    navigate,
    isTestingBarcodeScanError,
    showInvalidQRCodeToast,
    siteId,
    toast,
    setupGenericFail,
    setupSuccess,
    setupUnavailableFail,
  ]);

  return (
    <PageBase h="100%">
      <TopNav title="Finalize Activation" backURL={homeWifiDetails} />

      {isRequestPending ? (
        <VStack>
          <Box px={8} mt={4}>
            <Text fontWeight={'bold'}>Please Wait</Text>
            <Text>We are activating your Schneider Charge, this may take up to a few minutes.</Text>
          </Box>
          <CircularLoader color="#57BB59" maxW={162} mt={16} trackWidth={16} />
        </VStack>
      ) : (
        <Flex direction={'column'} align="center" justify={'space-between'} h="100%" maxH={800}>
          <Text px={8} mt={4}>
            Scan the QR code on the Schneider Charge to finalize the activation process. After this takes place, you may
            have to wait a few minutes your device to restart.
          </Text>

          <Image
            src={schneiderChargeDeviceQRImg}
            alt="Schneider Charge Device Wifi Scan"
            my={8}
            w="100%"
            maxW={200}
            mx="auto"
          />
          <Flex direction={'column'} align="center" justify={'center'} width="100%" mb={BOTTOM_NAV_HEIGHT}>
            <Button
              data-testid={'open-camera'}
              variant="solid"
              size="md"
              rounded={50}
              colorScheme="dusk100"
              minW={180}
              px={8}
              fontWeight={500}
              py={6}
              mb={6}
              onClick={handleStartQRCodeScan}
              isDisabled={!IS_NATIVE && !IS_RUNNING_CYPRESS_TESTS}
            >
              {IS_NATIVE || IS_RUNNING_CYPRESS_TESTS ? 'Open Camera to Scan QR Code' : 'Scanning not available on web'}
            </Button>
          </Flex>
        </Flex>
      )}
    </PageBase>
  );
}
