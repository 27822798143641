import MultiToggle, { ReactMultiToggleProps } from 'react-multi-toggle';
import React, { useMemo } from 'react';
import { Box } from '@chakra-ui/layout';
import { BoxProps, Center, Flex, Spinner, Text } from '@chakra-ui/react';

export interface SwitchOption<T> {
  label?: string | React.ReactNode;
  value: T;
}

interface SwitchProps<T extends string = string> {
  switchOptions: SwitchOption<T>[];
  onChange: (newValue: T) => void;
  value: T;
  disabled?: boolean;
  setValueAsBottomLabel?: boolean;
  enableBottomLabel?: boolean;
  showOnlySelectedValue?: boolean;
  customClassName?: string;
}

const modifyOptions = <T extends string = string>(options: SwitchOption<T>[]) =>
  options.map((option) => {
    return {
      value: option.value,
      displayName: option.label,
    };
  });

export default function MultiToggleSwitch<T extends string = string>({
  switchOptions,
  style,
  onChange,
  value,
  disabled = false,
  enableBottomLabel = false,
  showOnlySelectedValue = false,
  setValueAsBottomLabel = true,
  customClassName = '',
  ...chakraProps
}: ReactMultiToggleProps<string> & SwitchProps<T> & Omit<BoxProps, 'onChange'>) {
  const options = useMemo(() => modifyOptions(switchOptions), [switchOptions]);

  let bottomLabelText = chakraProps.label;
  if (setValueAsBottomLabel) bottomLabelText = value.charAt(0).toUpperCase() + value.slice(1);
  if (disabled) bottomLabelText = 'Updating...';

  return (
    <Box style={style} {...chakraProps}>
      <MultiToggle<T>
        options={options}
        selectedOption={value}
        onSelectOption={(selectedValue) => !disabled && value !== selectedValue && onChange(selectedValue)}
        className={
          showOnlySelectedValue
            ? `${disabled ? 'switch-disabled' : ''} hide-non-selected`
            : `${disabled ? 'switch-disabled' : ''} container-width-${options.length} ${customClassName}`
        }
      />

      {enableBottomLabel && (
        <Flex justify="center" py={1} align="center" data-testid="multi-toggle-bottom-text">
          <Center mr={1}>{disabled && <Spinner emptyColor="gray.200" color="blue.500" size="sm" />}</Center>
          <Text fontWeight="normal" color={disabled ? 'gray.500' : ''}>
            {bottomLabelText}
          </Text>
        </Flex>
      )}
    </Box>
  );
}
