import React from 'react';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputRightAddon,
  Heading,
  Button,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TOUTariffFormData } from './types';
import { InputGroup } from '@chakra-ui/input';
import { DeleteIcon } from '@chakra-ui/icons';
import { getEmptyRateTemplate } from './constants';
import TimeOfUseFieldArray from './TimeOfUseFieldArray';

type Props = {
  seasonIndex: number;
};

export default function ConfigureTOURate({ seasonIndex }: Props) {
  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<TOUTariffFormData>();
  const {
    fields: shoulderRates,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `seasons.${seasonIndex}.shoulderRates`,
  });
  const seasonErrors = errors?.seasons?.[seasonIndex];
  const hasPeakRate = watch(`seasons.${seasonIndex}.peakRate`);
  const hasOffPeakRate = watch(`seasons.${seasonIndex}.offPeakRate`);
  const ratesAreInclusiveOfGST = watch(`ratesAreInclusiveOfGST`);

  function handleAddShoulderRate() {
    append(getEmptyRateTemplate({ type: 'SHOULDER' }));
  }

  function handleAddPeakRate() {
    setValue(`seasons.${seasonIndex}.peakRate`, getEmptyRateTemplate({ type: 'PEAK' }));
  }

  function handleAddOffPeakRate() {
    setValue(`seasons.${seasonIndex}.offPeakRate`, getEmptyRateTemplate({ type: 'OFF-PEAK' }));
  }

  return (
    <Box data-testid={`season-${seasonIndex}-rates-container`}>
      <Box>
        <Flex align="center">
          <Heading size="md">Peak</Heading>
          {hasPeakRate ? (
            <IconButton
              ml={1}
              size="sm"
              onClick={() => setValue(`seasons.${seasonIndex}.peakRate`, null)}
              color="customRed.500"
              variant="ghost"
              icon={<DeleteIcon />}
              aria-label={`Delete peak rate`}
              data-testid={`season-${seasonIndex}-peak-rate-delete-btn`}
            />
          ) : (
            <Button
              onClick={handleAddPeakRate}
              ml={2}
              variant="ghost"
              color="customBlue.500"
              size={'sm'}
              data-testid={`season-${seasonIndex}-add-peak-rate`}
            >
              Add
            </Button>
          )}
        </Flex>
        {hasPeakRate && (
          <>
            <FormControl w={['100%', '100%', '75%', '50%']} isInvalid={!!seasonErrors?.peakRate?.tiers?.[0]?.rate}>
              <FormLabel mb={0} mr={0} fontWeight="bold">
                Rate
              </FormLabel>

              <InputGroup>
                <Input
                  data-testid={`season-${seasonIndex}-peak-rate-input`}
                  {...register(`seasons.${seasonIndex}.peakRate.tiers.0.rate` as const)}
                  type={'number'}
                  onWheel={(e) => e.currentTarget.blur()}
                  min={0}
                  step="0.0000000001"
                />
                <InputRightAddon>$ / kWh {ratesAreInclusiveOfGST ? '(incl. GST)' : '(excl. GST)'}</InputRightAddon>
              </InputGroup>
              <FormErrorMessage data-testid={`season-${seasonIndex}-peak-rate-error-message`}>
                {seasonErrors?.peakRate?.tiers?.[0]?.rate?.message}
              </FormErrorMessage>
            </FormControl>

            <TimeOfUseFieldArray formPath={`seasons.${seasonIndex}.peakRate`} />
          </>
        )}
      </Box>

      <Divider my={3} />

      <Flex align="center">
        <Heading size="md">Shoulder</Heading>
        <Button
          onClick={handleAddShoulderRate}
          ml={2}
          variant="ghost"
          color="customBlue.500"
          size={'sm'}
          data-testid={`season-${seasonIndex}-add-shoulder-rate`}
        >
          Add {shoulderRates.length > 0 && 'Another Shoulder'}
        </Button>
      </Flex>
      {shoulderRates.map((rate, rateIndex) => {
        const rateErrors = errors?.seasons?.[seasonIndex]?.shoulderRates?.[rateIndex];

        return (
          <Box key={rate.id}>
            <FormControl w={['100%', '100%', '75%', '50%']} isInvalid={!!rateErrors?.tiers?.[0]?.rate} mt={3}>
              <Flex align="center">
                <FormLabel mb={0} mr={1} fontWeight="bold">
                  Rate {rateIndex + 1}
                </FormLabel>

                <IconButton
                  ml={1}
                  size="sm"
                  onClick={() => remove(rateIndex)}
                  color="customRed.500"
                  variant="ghost"
                  icon={<DeleteIcon />}
                  aria-label={`Delete shoulder rate ${rateIndex + 1}`}
                  data-testid={`season-${seasonIndex}-shoulder-rate-${rateIndex}-delete-btn`}
                />
              </Flex>
              <InputGroup>
                <Input
                  data-testid={`season-${seasonIndex}-shoulder-rate-input-${rateIndex}`}
                  {...register(`seasons.${seasonIndex}.shoulderRates.${rateIndex}.tiers.0.rate` as const)}
                  type={'number'}
                  onWheel={(e) => e.currentTarget.blur()}
                  min={0}
                  step="0.0000000001"
                />
                <InputRightAddon>$ / kWh {ratesAreInclusiveOfGST ? '(incl. GST)' : '(excl. GST)'}</InputRightAddon>
              </InputGroup>
              <FormErrorMessage data-testid={`season-${seasonIndex}-shoulder-rate-${rateIndex}-error-message`}>
                {rateErrors?.tiers?.[0]?.rate?.message}
              </FormErrorMessage>
            </FormControl>

            <TimeOfUseFieldArray formPath={`seasons.${seasonIndex}.shoulderRates.${rateIndex}`} />

            {shoulderRates.length > 1 && rateIndex !== shoulderRates.length - 1 && <Divider my={4} />}
          </Box>
        );
      })}

      <Divider my={3} />

      <Box>
        <Flex align="center">
          <Heading size="md">Off-peak</Heading>
          {hasOffPeakRate ? (
            <IconButton
              ml={1}
              size="sm"
              onClick={() => setValue(`seasons.${seasonIndex}.offPeakRate`, null)}
              color="customRed.500"
              variant="ghost"
              icon={<DeleteIcon />}
              aria-label={`Delete off-peak rate`}
              data-testid={`season-${seasonIndex}-off-peak-rate-delete-btn`}
            />
          ) : (
            <Button
              onClick={handleAddOffPeakRate}
              ml={2}
              variant="ghost"
              color="customBlue.500"
              size={'sm'}
              data-testid={`season-${seasonIndex}-add-off-peak-rate`}
            >
              Add
            </Button>
          )}
        </Flex>
        {hasOffPeakRate && (
          <>
            <FormControl w={['100%', '100%', '75%', '50%']} isInvalid={!!seasonErrors?.offPeakRate?.tiers?.[0]?.rate}>
              <FormLabel mb={0} mr={0} fontWeight="bold">
                Rate
              </FormLabel>

              <InputGroup>
                <Input
                  data-testid={`season-${seasonIndex}-off-peak-rate-input`}
                  {...register(`seasons.${seasonIndex}.offPeakRate.tiers.0.rate` as const)}
                  type={'number'}
                  onWheel={(e) => e.currentTarget.blur()}
                  min={0}
                  step="0.0000000001"
                />
                <InputRightAddon>$ / kWh {ratesAreInclusiveOfGST ? '(incl. GST)' : '(excl. GST)'}</InputRightAddon>
              </InputGroup>
              <FormErrorMessage data-testid={`season-${seasonIndex}-off-peak-rate-error-message`}>
                {seasonErrors?.offPeakRate?.tiers?.[0]?.rate?.message}
              </FormErrorMessage>
            </FormControl>

            <TimeOfUseFieldArray formPath={`seasons.${seasonIndex}.offPeakRate`} />
          </>
        )}
      </Box>
    </Box>
  );
}
