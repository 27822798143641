import { UserData } from 'clipsal-cortex-types/src/api/api-user';
import LogRocket from 'logrocket';
import packageJSON from '../../../package.json';
import * as amplitude from '@amplitude/analytics-browser';

interface LoginError {
  code: string;
  name: string;
  message: string;
}

/**
 * Parses a provided login error.
 *
 * @param message - The error message to display to the user.
 */
export function parseLoginError({ message }: LoginError) {
  window.alert(message);
}

// urls with private data that we want to hide from logrocket
const PROTECTED_URL_REGEX = [/\/v1\/user(.*)$/];

/**
 * Configures the Log Rocket setup for this repo.
 */
export function setupLogRocket(userData: UserData) {
  LogRocket.init('lwzsxr/clipsal-pulse', {
    release: packageJSON.version,
    network: {
      requestSanitizer: (request) => {
        const { url } = request;
        const hasPrivateData = PROTECTED_URL_REGEX.some((urlRegex) => urlRegex.test(url));
        if (hasPrivateData) request.body = '';

        if (request.headers) {
          request.headers['authorization'] = '';
          request.headers['Authorization'] = '';
        }

        return request;
      },
      responseSanitizer: (response) => {
        // log rocket type definition does not have url but there is url in response
        const url = ('url' in response ? response?.url : '') as string;
        const hasPrivateData = PROTECTED_URL_REGEX.some((urlRegex) => urlRegex.test(url));
        return hasPrivateData ? null : response;
      },
    },
  });

  if (userData?.user_id) {
    LogRocket.identify(userData.user_id.toString(), {
      role: userData.role,
      tenantId: userData.tenant_id,
      version: packageJSON.version,
    });
  }
}

export async function setupAmplitude(userData: UserData) {
  await amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, userData?.user_id?.toString(), {
    appVersion: packageJSON.version,
    defaultTracking: {
      // Default page views will send an event on initial page load, but won't update event properties to the values
      // we do on route change (i.e. updating path from '/site/123/dashboard' to '/site/<id>/dashboard`.
      // Because of this, the default page view mechanism is disabled, and we manually trigger these events through
      // react router (see App.tsx).
      pageViews: false,
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
    },
  }).promise;

  // Initial page view event -- if we got here, the user is on some sort of page
  // replace the site ID with a placeholder
  const pathname = window.location.pathname.replace(/^\/site\/\d+\//, '/site/<id>/');

  // Required event properties for page views
  const eventProperties: Record<string, string> = {
    '[Amplitude] Page Path': pathname,
  };

  if (window.location) {
    const { hostname, href, origin, host, pathname } = window.location;
    eventProperties['[Amplitude] Page Domain'] = hostname;
    eventProperties['[Amplitude] Page Location'] = href;
    eventProperties['[Amplitude] Page URL'] = href;
    eventProperties['Previous Path'] = 'FIRST_APP_VISIT';
    eventProperties['Seconds Spent on Previous Path'] = 'FIRST_APP_VISIT';
    eventProperties['LogRocket Session URL'] = LogRocket.sessionURL ?? 'NO_SESSION_AVAILABLE';
    eventProperties['referrer'] = origin + pathname;
    eventProperties['referring_domain'] = host;
  }
  // Log the first page view event.
  amplitude.logEvent('[Amplitude] Page Viewed', eventProperties);
}
