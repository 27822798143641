import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, AlertIcon, Button, Flex, Grid, Heading, Image, Skeleton, Text } from '@chakra-ui/react';
import { CommonEnergyPlanProps } from './EnergyPlan';
import { formatDollarsNoCents } from 'clipsal-cortex-utils/src/formatting/number-formatting';
import { useViewportType } from '../../../common/hooks/use-viewport-type';
import { useMostRecentTariff } from '../tariffs/tariffApi';
import errorImage from '../../../assets/images/not_found_exclaimation.svg';
import { useGetRetailerImageURLQuery, useGetSiteRetailPlanCheckQuery } from '../billsApi';
import { useNavigate } from 'react-router-dom';
import { selectSite } from '../../site/siteSlice';
import { ChevronRightIcon } from '@chakra-ui/icons';

export function EnergyPlanDetails({ isParentLoaded, isInPlanComparison }: CommonEnergyPlanProps) {
  const {
    data: mostRecentTariff,
    isLoading: isTariffsDataLoading,
    isError: isTariffsDataError,
  } = useMostRecentTariff();
  const navigate = useNavigate();
  const site = useSelector(selectSite);
  const { data: rpcData, isLoading: isRPCLoading, isError: isRPCError } = useGetSiteRetailPlanCheckQuery(site.site_id);
  const isError = isTariffsDataError || isRPCError;
  const isLoaded = !isTariffsDataLoading && !isRPCLoading && isParentLoaded;
  const { data, isLoading: isRetailerImageLoading } = useGetRetailerImageURLQuery(
    mostRecentTariff?.tariff.retailer.id ?? 0,
    {
      skip: !mostRecentTariff?.tariff.retailer.id,
    }
  );
  const imageBase64 = data?.base64_file ?? '';
  const { isDesktopViewport } = useViewportType();

  if (isError) {
    return (
      <Flex height={'100%'} my={8} justify={'center'} align={'center'} direction={'column'} width={'100%'}>
        <Image src={errorImage} alt={'Red error exclamation mark'} />
        <Heading textAlign={'center'} m={2} size={'md'}>
          Something went wrong. Please try reloading the page.
        </Heading>
      </Flex>
    );
  }

  if (!isTariffsDataLoading && !mostRecentTariff) {
    return (
      <Alert mt={3} status="warning" variant="left-accent">
        <AlertIcon />
        <Flex flexDirection={['column', 'column', 'row']}>
          <Text>We're missing energy rate data for your site.</Text>
          <Button
            ml={[0, 0, 3]}
            mt={[2, 2, 0]}
            onClick={() => {
              navigate(`/site/${site.site_id}/bills/tariffs/new`);
            }}
            rounded={20}
            colorScheme="dusk100"
          >
            Setup Energy Rate
          </Button>
        </Flex>
      </Alert>
    );
  }

  return (
    <>
      <Grid mt={4} templateColumns={['50px 1fr', '50px 1fr', '75px 1fr']}>
        <Skeleton isLoaded={!isRetailerImageLoading}>
          {imageBase64 ? <Image src={'data:image/png;base64,' + imageBase64} pr={'2'} alt={'Retailer logo'} /> : null}
        </Skeleton>

        <Flex justify={'space-between'} align={'center'}>
          <Flex justify={'center'} direction={'column'}>
            <Skeleton isLoaded={isLoaded}>
              <Heading ml={3} size={'sm'}>
                {mostRecentTariff!.tariff.retailer?.name}
              </Heading>
            </Skeleton>

            <Skeleton isLoaded={isLoaded}>{isDesktopViewport && <ViewDetailsButton />}</Skeleton>
          </Flex>

          {isDesktopViewport ? (
            !!rpcData?.current_bill_data &&
            isInPlanComparison && (
              <Skeleton isLoaded={isLoaded}>
                <Flex align={'center'} direction={'column'}>
                  <Heading size={'xl'}>{formatDollarsNoCents(rpcData.current_bill_data?.cost)}</Heading>
                  <Text color={'textGrey.500'} fontSize={'sm'}>
                    last bill ({rpcData.current_bill_data?.num_days} days)
                  </Text>
                </Flex>
              </Skeleton>
            )
          ) : (
            <ViewDetailsButton />
          )}
        </Flex>
      </Grid>
    </>
  );
}

function ViewDetailsButton() {
  const { site_id: siteId } = useSelector(selectSite);
  const {
    data: mostRecentTariff,
    isLoading: isTariffsDataLoading,
    isError: isTariffsDataError,
  } = useMostRecentTariff();
  const navigate = useNavigate();

  if (isTariffsDataLoading || isTariffsDataError || !mostRecentTariff) return <></>;

  return (
    <Button
      px={3}
      mt={1}
      variant={'ghost'}
      onClick={() => {
        navigate(`/site/${siteId}/bills/tariffs/${mostRecentTariff.id}/review?backURL=/site/${siteId}/bills`);
      }}
      color={'customBlue.500'}
      data-testid="show-plan-details-button"
    >
      <Flex align="center">
        View Details <ChevronRightIcon w={5} h={5} ml={1} />
      </Flex>
    </Button>
  );
}
