import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  useColorModeValue,
  Radio,
  Text,
} from '@chakra-ui/react';
import React from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  header: string;
  subHeader: string;
  confirmButtonName?: string;
  cancelButtonName?: string;
  dontShowRadioText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  dialogContentTestId?: string;
  cancelButtonTestId?: string;
  confirmButtonTestId?: string;
  confirmButtonTextColor?: string;
  onSelectDontShowAgain?: () => void;
  isConfirming?: boolean;
  closeOnConfirm?: boolean;
};

const AlertDialogModal = ({
  isOpen,
  onClose,
  header,
  subHeader,
  confirmButtonName = 'OK',
  onConfirm,
  cancelButtonName = 'Cancel',
  onCancel,
  dialogContentTestId = 'alert-dialog-modal',
  cancelButtonTestId = 'alert-dialog-cancel-btn',
  confirmButtonTestId = 'alert-dialog-confirm-btn',
  confirmButtonTextColor = '#008A16',
  onSelectDontShowAgain,
  isConfirming,
  closeOnConfirm = true,
  dontShowRadioText = "Don't show alerts anymore",
}: Props) => {
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const buttonBorderColor = useColorModeValue('#D9D9D9', '#494B50');
  const backgroundColor = useColorModeValue('white', '#363E40');

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent
          top={'220px'}
          bgColor={backgroundColor}
          mx={8}
          rounded={16}
          mt={30}
          overflowX="hidden"
          data-testid={dialogContentTestId}
        >
          {header && (
            <AlertDialogHeader
              textAlign="center"
              pt={6}
              pb={0}
              fontSize="lg"
              fontWeight="bold"
              mx="auto"
              overflowWrap="anywhere"
            >
              {header}
            </AlertDialogHeader>
          )}

          {subHeader && (
            <AlertDialogBody pb={4} mx="auto" textAlign="center">
              {subHeader}
            </AlertDialogBody>
          )}

          {onSelectDontShowAgain && (
            <Radio
              size="lg"
              onChange={onSelectDontShowAgain}
              justifyContent="center"
              mb={3}
              mt={0}
              data-testid="alert-dialog-dont-show-again-radio-input"
            >
              <Text fontSize="14px">{dontShowRadioText}</Text>
            </Radio>
          )}

          <Flex w="100%" justifyContent={'space-around'}>
            <Button
              w="100%"
              rounded={0}
              py={6}
              fontWeight="normal"
              borderRight="1px solid"
              borderTop="1px solid"
              borderColor={buttonBorderColor}
              variant="ghost"
              ref={cancelRef}
              onClick={onCancel || onClose}
              data-testid={cancelButtonTestId}
            >
              {cancelButtonName}
            </Button>
            <Button
              data-testid={confirmButtonTestId}
              color={confirmButtonTextColor}
              w="100%"
              rounded={0}
              py={6}
              variant="ghost"
              borderTop="1px solid"
              borderColor={buttonBorderColor}
              onClick={() => {
                onConfirm();
                if (closeOnConfirm) onClose();
              }}
              isLoading={isConfirming}
            >
              {confirmButtonName}
            </Button>
          </Flex>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertDialogModal;
